import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { success } from '../../../Global/toast';
import CrossSVG from '../../../_admin/assets/media/svg_uTrade/cross-red.svg';
import EditSVG from '../../../_admin/assets/media/svg_uTrade/edit-round.svg';
import UploadSVG from '../../../_admin/assets/media/svg_uTrade/upload-2.svg';
import { CustomComponentAfterSelect } from '../../custom/Select/CustomComponentAfterSelect';
import { CustomSelectWhite } from '../../custom/Select/CustomSelectWhite';
import APICallService from '../../../api/apiCallService';
import {
  advertisements,
  manageProduct,
  platformFees,
  seller,
} from '../../../api/apiEndPoints';
import Method from '../../../utils/methods';
import {
  Actived,
  ApprovedProduct,
  CategoryPage,
  HomePage,
  MultipleProduct,
  PAGE_LIMIT,
  SingleProduct,
} from '../../../utils/constants';
import clsx from 'clsx';
import { advertisementJSON } from '../../../api/apiJSON/advertisement';
import { fileValidation } from '../../../Global/fileValidation';
import { advertismentToast } from '../../../utils/toast';
function AddNewAdvertisement(props: any) {
  const navigate = useNavigate();
  var currentDate = new Date();
  // Create a new date by adding one day to the current date
  var tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);
  // Format the date in 'yyyy-mm-dd' format
  var formattedTomorrow = tomorrow.toISOString().slice(0, 10);
  const [advertisement, setAdvertisement] = useState<{
    startDate: string;
    endDate: string;
    seller: string;
    placement: string;
    productType: string;
    amount: Number;
    totalAmount: Number;
    variants: any;
    image: string;
    imageReader: any;
  }>({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: formattedTomorrow,
    seller: '',
    placement: '1',
    productType: '1',
    amount: 0,
    totalAmount: 0,
    variants: [],
    image: '',
    imageReader: {},
  });
  const [validation, setValidation] = useState<{
    date: boolean;
    seller: boolean;
    placement: boolean;
    productType: boolean;
    variants: boolean;
    image: boolean;
  }>({
    date: false,
    seller: false,
    placement: false,
    productType: false,
    variants: false,
    image: false,
  });
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [filterProducts, setFilterProducts] = useState<any>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>('1');
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalSellers, setTotalSellers] = useState(0);
  const [sellerData, setSellerData] = useState<
    [
      // {
      //   name: string;
      //   ownerName: string;
      //   address: string;
      //   about: string;
      //   categories: any;
      //   image: any;
      //   imageReader: any;
      //   types: string;
      //   certificates: any;
      //   phone: string;
      //   email: string;
      //   phoneCountry: string;
      // }
    ]
  >([]);
  const [product, setProductData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedSeller, setSelectedSeller] = useState('');
  const [productPage, setProductPage] = useState(1);
  const [fees, setFees] = useState<any>();
  useEffect(() => {
    (async () => {
      await fetchInitData(1, PAGE_LIMIT);
    })();
  }, []);
  useEffect(() => {
    let tempValidation: any = { ...validation };
    if (
      !Method.checkisSameOrAfter(advertisement.startDate, advertisement.endDate)
    ) {
      tempValidation.date = true;
    } else {
      tempValidation.date = false;
    }
    setValidation(tempValidation);
  }, [advertisement]);
  const fetchInitData = async (page: number , limit: number , search: string = '') => {
    setFetchLoading(true);
    let params = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: 'name',
      sortOrder: 1,
      ['status[0]']: 1,
      ['status[1]']: 2,
      ['status[2]']: 3,
      ['status[3]']: 4,
    };
    let apiService = new APICallService(seller.listSeller, params);
    let response = await apiService.callAPI();
    if(response.records){
      setTotalSellers(response.total);
      let data: any = (prevResults: any) => page === 1 ? response.records : [...prevResults, ...response.records]
      setSellerData(data);
    }
    let feesApiService = new APICallService(platformFees.listOtherFees);
    let feesResponse = await feesApiService.callAPI();
    if (feesResponse) {
      setAmount(feesResponse);
      setFees(feesResponse);
    }
    setFetchLoading(false)
  };

  const onMenuScrollToBottom = async () => {
    if (!(sellerData.length === totalSellers)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      await fetchInitData(tempPage, PAGE_LIMIT, searchTerm);
    }
  };
  const onMenuClose = async () => {
    setSearchTerm('');
  };
  const onMenuOpen = async () => {
    setPage(1);
    await fetchInitData(1,PAGE_LIMIT, searchTerm);
  };
  const handleInputChange = async (inputValue: string) => {
    setPage(1);
    setSearchTerm(inputValue);
    await fetchInitData(1, PAGE_LIMIT, inputValue);
  };

  const handleImageInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (event.target.files) {
    //   setSelectedImage(event.target.files[0]);
    //   setImagePreviewUrl(URL.createObjectURL(event.target.files[0]));
    // }
    const selectedFiles = event.target.files;
    let temp = { ...advertisement };
    let tempValidation: any = { ...validation };
    tempValidation.image = true;
    if (!selectedFiles) return;
    else {
      if (fileValidation(selectedFiles?.[0])) {
        temp.image = URL.createObjectURL(selectedFiles?.[0]);
        temp.imageReader = selectedFiles?.[0];
        tempValidation.image = false;
      }
    }
    setValidation(tempValidation);
    setAdvertisement(temp);
  };
  const handleProductChange = (event: any) => {
    let temp: any = { ...advertisement };
    let tempValidation: any = { ...validation };
    tempValidation.variants = false;
    if (advertisement.productType === SingleProduct.toString()) {
      let data: any = [];
      data.push(event.id);
      temp.variants = data;
      setFilterProducts([event]);
    } else {
      let data: any = [];
      event.map((val: any) => {
        data.push(val.id);
      });
      temp.variants = data;
      setFilterProducts(event);
    }
    if (!temp.variants.length) {
      tempValidation.variants = true;
    }
    setValidation(tempValidation);
    setAdvertisement(temp);
  };
  const handleChange = async (value: any, name: string) => {
    let temp: any = { ...advertisement };
    let tempValidation: any = { ...validation };
    temp[name] = value;
    tempValidation[name] = false;
    if (value === '') {
      tempValidation[name] = true;
    }
    let totalAmount = 0;
    let amount = 0;
    if (temp.placement === HomePage.toString()) {
      if (fees?.advertisement) {
        if (temp.productType === SingleProduct.toString()) {
          totalAmount =
            Method.dayDifference(temp.startDate, temp.endDate) > 0
              ? Method.dayDifference(temp.startDate, temp.endDate) *
                fees?.advertisement.homePageSingleProduct
              : 1 * fees?.advertisement.homePageSingleProduct;
          amount = fees?.advertisement.homePageSingleProduct;
        } else {
          totalAmount =
            Method.dayDifference(temp.startDate, temp.endDate) > 0
              ? Method.dayDifference(temp.startDate, temp.endDate) *
                fees?.advertisement.homePageMultipleProduct
              : 1 * fees?.advertisement.homePageMultipleProduct;
          amount = fees?.advertisement.homePageMultipleProduct;
        }
      }
    } else {
      if (fees?.advertisement)
        if (temp.productType === SingleProduct.toString()) {
          totalAmount =
            Method.dayDifference(temp.startDate, temp.endDate) > 0
              ? Method.dayDifference(temp.startDate, temp.endDate) *
                fees?.advertisement.categoryPageSingleProduct
              : 1 * fees?.advertisement.categoryPageSingleProduct;
          amount = fees?.advertisement.categoryPageSingleProduct;
        } else {
          totalAmount =
            Method.dayDifference(temp.startDate, temp.endDate) > 0
              ? Method.dayDifference(temp.startDate, temp.endDate) *
                fees?.advertisement.categoryPageMultipleProduct
              : 1 * fees?.advertisement.categoryPageMultipleProduct;
          amount = fees?.advertisement.categoryPageMultipleProduct;
        }
    }
    temp.amount = amount;
    temp.totalAmount = totalAmount;
    setValidation(tempValidation);
    setAdvertisement(temp);
  };
  const setAmount = async (advFees: any) => {
    let temp: any = { ...advertisement };
    let totalAmount = 0;
    let amount = 0;
    if (advFees.placement === HomePage.toString()) {
      if (advFees?.advertisement) {
        if (advertisement.productType === SingleProduct.toString()) {
          totalAmount =
            Method.dayDifference(
              advertisement.startDate,
              advertisement.endDate
            ) > 0
              ? Method.dayDifference(
                  advertisement.startDate,
                  advertisement.endDate
                ) * advFees?.advertisement.homePageSingleProduct
              : 1 * advFees?.advertisement.homePageSingleProduct;
          amount = advFees?.advertisement.homePageSingleProduct;
        } else {
          totalAmount =
            Method.dayDifference(
              advertisement.startDate,
              advertisement.endDate
            ) > 0
              ? Method.dayDifference(
                  advertisement.startDate,
                  advertisement.endDate
                ) * advFees?.advertisement.homePageMultipleProduct
              : 1 * advFees?.advertisement.homePageMultipleProduct;
          amount = advFees?.advertisement.homePageMultipleProduct;
        }
      }
    } else {
      if (advFees?.advertisement)
        if (advertisement.productType === SingleProduct.toString()) {
          totalAmount =
            Method.dayDifference(
              advertisement.startDate,
              advertisement.endDate
            ) > 0
              ? Method.dayDifference(
                  advertisement.startDate,
                  advertisement.endDate
                ) * advFees?.advertisement.categoryPageSingleProduct
              : 1 * advFees?.advertisement.categoryPageSingleProduct;
          amount = advFees?.advertisement.categoryPageSingleProduct;
        } else {
          totalAmount =
            Method.dayDifference(
              advertisement.startDate,
              advertisement.endDate
            ) > 0
              ? Method.dayDifference(
                  advertisement.startDate,
                  advertisement.endDate
                ) * advFees?.advertisement.categoryPageMultipleProduct
              : 1 * advFees?.advertisement.categoryPageMultipleProduct;
          amount = advFees?.advertisement.categoryPageMultipleProduct;
        }
    }
    temp.amount = amount;
    temp.totalAmount = totalAmount;
    setAdvertisement(temp);
  };
  const handleRemove = (index: number) => {
    let temp = [...filterProducts];
    let tempValidation: any = { ...validation };
    let advTemp: any = { ...advertisement };
    let updated = temp.filter((e: any, i: number) => i !== index);
    let updatedVariants = advTemp.variants.filter(
      (e: any, i: number) => i !== index
    );
    if (!updatedVariants.length) {
      tempValidation.variants = true;
    }
    advTemp.variants = updatedVariants;
    setAdvertisement(advTemp);
    setValidation(tempValidation);
    setFilterProducts(updated);
  };
  const handleSellerSelect = async (
    value: any,
  ) => {
    if (value) {
      setSelectedSeller(value);
      await fetchProducts(value,productPage, PAGE_LIMIT, searchTerm);
    }else{
      setSelectedSeller('');
    }
  };

  const fetchProducts = async (
    businessId: string,
    page: number,
    limit: number, 
    search: string = '') => {
    setProductLoading(true);
    let temp: any = { ...advertisement };
    let tempValidation: any = { ...validation };
    temp.seller = businessId;
    tempValidation.seller = false;
    let params = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: 'title',
      sortOrder: 1,
      state: Actived,
      ['status[]']: ApprovedProduct,
      ['business[]']: businessId,
      needCount: true
    };
    let apiService = new APICallService(manageProduct.listVariant, params);
    let response = await apiService.callAPI();
    if (response.records) {
      setTotalRecords(response.total);
      let data: any = (prevResults: any) => page === 1 ? response.records : [...prevResults, ...response.records]
      setProductData(data);
    } else {
      setProductData([]);
    }
    setValidation(tempValidation);
    setAdvertisement(temp);
    setProductLoading(false);
  };

  const onMenuProductScrollToBottom = async () => {
    if (!(product.length === totalRecords)) {
      let tempPage = productPage;
      tempPage = tempPage + 1;
      setProductPage(tempPage);
      await fetchProducts(selectedSeller,tempPage, PAGE_LIMIT, searchTerm);
    }
  };
  const onMenuProductClose = async () => {
    setSearchTerm('');
  };
  const onMenuProductOpen = async () => {
    setProductPage(1);
    if(selectedSeller){
    await fetchProducts(selectedSeller, 1 ,PAGE_LIMIT, searchTerm);
    }
  };
  const handleProductInputChange = async (inputValue: any) => {
    setProductPage(1);
    setSearchTerm(inputValue);
    if(selectedSeller){
    await fetchProducts(selectedSeller, 1 , PAGE_LIMIT, inputValue);
    }
  };

  const handleSubmit = async () => {
    let temp: any = { ...advertisement };
    let tempValidation: any = { ...validation };
    if (temp.seller === '') {
      tempValidation.seller = true;
    }
    if (!temp.variants.length) {
      tempValidation.variants = true;
    }
    if (temp.image === '') {
      tempValidation.image = true;
    }
    let allTrue = Object.values(tempValidation).every((el) => el === false);
    if (allTrue) {
      setLoading(true);
      let apiService = new APICallService(
        advertisements.addAddvertisement,
        advertisementJSON.addAdvertisement(temp),
        {
          id: temp.seller,
        }
      );
      let response: any = await apiService.callAPI();
      if (response) {
        navigate('/advertisement/view-advertisement');
        success(advertismentToast.published);
      }
      setLoading(false);
    }
    setValidation(tempValidation);
  };
  return (
    <div className="p-9">
      <Row>
        <Col
          xs={12}
          className="mb-5"
        >
          <h1 className="fs-22 fw-bolder">Add new advertisement</h1>
        </Col>
        <Col lg={8}>
          <Card className="bg-light border border-r10px mb-6 mb-xl-9">
            <Card.Header className="border-0">
              <div className="card-title">
                <h2 className="fs-22 fw-bolder">Basic details</h2>
              </div>
            </Card.Header>
            <Card.Body className="pt-0 pb-5">
              <Row className="align-items-center">
                <Col
                  lg={12}
                  className="mb-6"
                >
                  <label
                    htmlFor=""
                    className="fs-16 fw-500 mb-3 required"
                  >
                    Seller name
                  </label>
                  <CustomSelectWhite
                    options={
                      sellerData?.length ?
                      sellerData.map((val: any, index: number) => {
                        return {
                          title: val.name,
                          label: val.name,
                          _id: val._id,
                        };
                      })
                      : []
                    }
                    border={validation.seller ? '#e55451' : '#e0e0df'}
                    onMenuScrollToBottom = {onMenuScrollToBottom}
                    onMenuClose = {() => {
                      onMenuClose();
                    }}
                    onMenuOpen = {() => {
                      onMenuOpen();
                    }}
                    onInputChange={(newValue: any, { action }: any) => {
                      if (action === 'input-change') {
                        handleInputChange(newValue);
                      }
                    }}
                    onChange={(event: any) => {
                      handleSellerSelect(event._id);
                    }}
                    loading={fetchLoading}
                    isDisabled={loading}
                    isMulti={false}
                  />
                  {validation.seller ? (
                    <label
                      htmlFor=""
                      className="fs-14 fw-500 mb-3 text-danger"
                    >
                      Please select seller details
                    </label>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col lg={12}>
                  <Row className="align-items-center">
                    <Col
                      lg={6}
                      className="mb-5"
                    >
                      <label
                        htmlFor=""
                        className="fs-16 fw-500 mb-3 required"
                      >
                        Start date
                      </label>
                      <div className="form-group">
                        <div
                          className="input-group date"
                          id="datetimepicker1"
                        >
                          <input
                            type="date"
                            value={advertisement.startDate.toString()}
                            disabled={loading}
                            min={new Date().toISOString().slice(0, 10)}
                            className={clsx(
                              'form-control form-control-custom bg-white',
                              validation.date ? 'border-danger' : ''
                            )}
                            onChange={(e) =>
                              handleChange(e.target.value, 'startDate')
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      className="mb-5"
                    >
                      <label
                        htmlFor=""
                        className="fs-16 fw-500 mb-3 required"
                      >
                        End date
                      </label>
                      <div className="form-group">
                        <div
                          className="input-group date"
                          id="datetimepicker1"
                        >
                          <input
                            type="date"
                            value={advertisement.endDate.toString()}
                            disabled={loading}
                            min={formattedTomorrow}
                            className={clsx(
                              'form-control form-control-custom bg-white',
                              validation.date ? 'border-danger' : ''
                            )}
                            onChange={(e) =>
                              handleChange(e.target.value, 'endDate')
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    {validation.date ? (
                      <Col lg={12}>
                        <label
                          htmlFor=""
                          className="fs-14 fw-500 text-danger"
                        >
                          Please select a valid start date and end date
                        </label>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="bg-light border border-r10px mb-6 mb-xl-9">
            <Card.Header className="border-0">
              <Card.Title>
                <h2 className="fs-22 fw-bolder text-dark">
                  Where do you want to place an advertisement?
                </h2>
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0 pb-2 border-bottom">
              <div className="d-flex flex-wrap mb-6 gap-2">
                <div className="form-check form-check-custom form-check-solid me-6 mb-lg-0">
                  <input
                    className="form-check-input h-30px w-30px h-md-35px w-md-35px"
                    type="checkbox"
                    value={HomePage.toString()}
                    disabled={loading}
                    onChange={(e) => {
                      handleChange(e.target.value, 'placement');
                    }}
                    checked={advertisement.placement === HomePage.toString()}
                  />
                  <label className="form-check-label text-dark fs-16 fw-600 ms-3">
                    Home page
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid me-6 mb-lg-0">
                  <input
                    className="form-check-input h-30px w-30px h-md-35px w-md-35px"
                    type="checkbox"
                    value={CategoryPage.toString()}
                    disabled={loading}
                    onChange={(e) => {
                      handleChange(e.target.value, 'placement');
                    }}
                    checked={
                      advertisement.placement === CategoryPage.toString()
                    }
                  />
                  <label className="form-check-label text-dark fs-16 fw-600 ms-3">
                    Category page
                  </label>
                </div>
              </div>
            </Card.Body>
            <Card.Header className="border-0">
              <Card.Title>
                <h2 className="fs-22 fw-bolder text-dark">
                  No. of product you want to show in the ad
                </h2>
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0 pb-2">
              <div className="d-flex flex-wrap mb-6 gap-2">
                <div className="form-check form-check-custom form-check-solid me-6 mb-lg-0">
                  <input
                    className="form-check-input h-30px w-30px h-md-35px w-md-35px"
                    type="checkbox"
                    value={SingleProduct.toString()}
                    disabled={loading}
                    id="SingleProduct"
                    checked={
                      advertisement.productType === SingleProduct.toString()
                    }
                    onChange={(e) => {
                      handleChange(e.target.value, 'productType');
                    }}
                  />
                  <label className="form-check-label text-dark fs-16 fw-600 ms-3">
                    Single
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid me-6 mb-lg-0">
                  <input
                    className="form-check-input h-30px w-30px h-md-35px w-md-35px"
                    type="checkbox"
                    value={MultipleProduct.toString()}
                    id="MultipleProducts"
                    disabled={loading}
                    checked={
                      advertisement.productType === MultipleProduct.toString()
                    }
                    onChange={(e) => {
                      handleChange(e.target.value, 'productType');
                    }}
                  />
                  <label className="form-check-label text-dark fs-16 fw-600 ms-3">
                    Multiple
                  </label>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="bg-light border border-r10px mb-6 mb-xl-9">
            <Card.Header className="border-0">
              <Card.Title>
                <h2 className="fs-22 fw-bolder">Product selection</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0 border-bottom">
              <Row className="align-items-center">
                <Col
                  lg={12}
                  className="mb-6"
                >
                  <Form.Label className="fs-16 fw-500 mb-2 text-dark required">
                    Select products for ad
                  </Form.Label>
                </Col>
                <Col lg={12}>
                  <CustomComponentAfterSelect
                    defaultValue={filterProducts}
                    value={filterProducts}
                    options={product && product.length
                      ? product
                          .map((productVal: any) => {
                            return {
                              name: productVal.title,
                              title: productVal.title.replace(/\s*\)\s*/g, ')'),
                              value: productVal._id,
                              id: productVal._id,
                              image: productVal.media[0].url,
                              label: (
                                <>
                                  <div className="symbol symbol-40px border me-2">
                                    <img
                                      src={productVal.media[0].url}
                                      className="object-fit-contain "
                                      alt=""
                                    />
                                  </div>
                                  <span className="fs-14 fw-500 text-black ">
                                    {productVal.title.replace(/\s*\)\s*/g, ')')}
                                  </span>
                                </>
                          ),
                            };
                          })
                      : []}
                    onChange={(event: any) => {
                      handleProductChange(event);
                    }}
                    onMenuScrollToBottom = {onMenuProductScrollToBottom}
                    onMenuClose = {() => {
                      onMenuProductClose();
                    }}
                    onMenuOpen = {() => {
                      onMenuProductOpen();
                    }}
                    onInputChange={(newValue: any, { action }: any) => {
                      if (action === 'input-change') {
                        handleProductInputChange(newValue);
                      }
                    }}
                    isDisabled={loading}
                    border={validation.variants ? '#e55451' : '#e0e0df'}
                    loading={productLoading}
                    isMulti={
                      advertisement.productType === MultipleProduct.toString()
                    }
                  />
                </Col>
                {validation.variants ? (
                  <Col lg={12}>
                    <label
                      htmlFor=""
                      className="fs-14 fw-500 text-danger"
                    >
                      Please select a products
                    </label>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Card.Body>
            {filterProducts.length ? (
              <>
                <Card.Header className="border-0">
                  <Card.Title>
                    <h5 className="fs-22 fw-bolder">
                      Added products for advertisement
                    </h5>
                  </Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                  <Row>
                    <Col
                      lg={12}
                      className="mt-5"
                    >
                      {filterProducts.map((productVal: any, index: number) => {
                        return (
                          <>
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <div className="d-flex">
                                <div className="symbol symbol-40px border bg-white me-2">
                                  <img
                                    width={40}
                                    height={40}
                                    src={productVal.image}
                                    className="object-fit-contain"
                                    alt=""
                                  />
                                </div>
                                <div className="fs-14 fw-500 align-self-center ms-1 text-dark">
                                  {productVal.name}
                                </div>
                              </div>
                              {!loading ? (
                                <Button
                                  variant=""
                                  className="btn btn-flush btn-icon"
                                >
                                  <img
                                    height={16}
                                    width={16}
                                    src={CrossSVG}
                                    onClick={() => {
                                      handleRemove(index);
                                    }}
                                    alt=""
                                  />
                                </Button>
                              ) : (
                                <></>
                              )}
                            </div>
                            {filterProducts.length === 1 ? (
                              <></>
                            ) : (
                              <div className="separator my-3"></div>
                            )}
                          </>
                        );
                      })}
                    </Col>
                  </Row>
                </Card.Body>
              </>
            ) : (
              <></>
            )}
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="bg-light border border-r10px">
            <Card.Header className="border-bottom-0">
              <Card.Title>
                <h5 className="fs-22 fw-bolder">Ad banner</h5>
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {advertisement.image ? (
                <div className="image-input image-input-outline min-w-xl-260px min-h-xl-150px border border-r10px bg-white">
                  <div
                    className="image-input-wrapper shadow-none bgi-contain w-100 h-100 bgi-position-center"
                    style={{
                      backgroundImage: `url(${advertisement.image})`,
                      backgroundRepeat: `no-repeat`,
                    }}
                  >
                    {advertisement.image && (
                      <img
                        width={262}
                        height={149}
                        src={advertisement.image}
                        className="img-fluid mh-150px border-r10px object-fit-contain"
                        alt=""
                      />
                    )}
                  </div>
                  {!loading ? (
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-50px h-50px "
                      data-kt-image-input-action="change"
                      title=""
                    >
                      <img
                        src={EditSVG}
                        alt=""
                        height={33}
                        width={33}
                      />
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageInput}
                        // onChange={(
                        //     e: React.ChangeEvent<HTMLInputElement>,
                        // ): void => props.onCertificateChange(e, index)}
                      />
                      <input
                        type="hidden"
                        name="avatar_remove"
                      />
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div
                  className={clsx(
                    'upload-btn-wrapper border-r10px bg-white',
                    validation.image ? 'border border-danger' : ''
                  )}
                >
                  <div>
                    <img
                      width={262}
                      height={149}
                      src={UploadSVG}
                      className="img-fluid object-fit-contain"
                      alt=""
                    />
                  </div>
                  <input
                    className="w-100 h-100"
                    type="file"
                    name="myfile"
                    onChange={handleImageInput}
                  />
                </div>
              )}
              <div className="d-flex">
                <span className="text-gray text-italic">{`Image dimensions:-(1080x1080px)`}</span>
              </div>
            </Card.Body>
          </Card>
          {validation.date && advertisement.amount ? (
            <></>
          ) : (
            <Card className="bg-light border border-r10px mt-7">
              <Card.Header className="border-bottom-0">
                <Card.Title>
                  <h5 className="fs-22 fw-bolder">Cost details</h5>
                </Card.Title>
              </Card.Header>
              <Card.Body className="pt-0 border-bottom">
                <Row>
                  <Col
                    xs={12}
                    className="mb-5"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fs-16 fw-500">Total days:</span>
                      <span className="fs-16 fw-600">
                        {Method.dayDifference(
                          advertisement.startDate,
                          advertisement.endDate
                        ) > 0
                          ? Method.dayDifference(
                              advertisement.startDate,
                              advertisement.endDate
                            ) + ' days'
                          : '1 day'}
                      </span>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fs-16 fw-500">Per day cost:</span>
                      <span className="fs-16 fw-600">
                        TSh {Method.getGeneralizedAmount(advertisement.amount)}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Body>
                <Row>
                  <Col xs={12}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fs-22 fw-bolder">Total cost</span>
                      <span className="fs-16 fw-bolder">
                        TSh{' '}
                        {Method.getGeneralizedAmount(advertisement.totalAmount)}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col xs={12}>
          {/* when published ads */}
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              // navigate("/advertisement/view-advertisement");
              // success("Advertisement Published");
              handleSubmit();
            }}
            disabled={loading}
          >
            {!loading && (
              <span className="indicator-label fs-16 fw-bolder">
                Publish advertisement
              </span>
            )}
            {loading && (
              <span
                className="indicator-progress indicator-label fs-16 fw-600"
                style={{ display: 'block' }}
              >
                Please wait...{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}{' '}
          </Button>
          {/* when edit ads */}
          {/* <Button variant="primary" size="lg">
            Save details
          </Button> */}
        </Col>
      </Row>
    </div>
  );
}
export default AddNewAdvertisement;
