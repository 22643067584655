import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { KTSVG } from '../../../../_admin/helpers';
import { CustomSelectWhite } from '../../../custom/Select/CustomSelectWhite';
import { useEffect, useState } from 'react';
import APICallService from '../../../../api/apiCallService';
import { buyer } from '../../../../api/apiEndPoints';
import Loader from '../../../../Global/loader';
import Method from '../../../../utils/methods';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../Global/pagination';
import { listBuyerOrder, listCustomerRefundDetails } from '../../../../utils/storeString';
import {
  OrderCancelled,
  OrderCash,
  OrderDelivered,
  OrderOutForDelivery,
  OrderProcessed,
  OrderSubmitted,
  PAGE_LIMIT,
} from '../../../../utils/constants';
import { getKey, setKey } from '../../../../Global/history';
import { refundListJSON } from '../../../../utils/staticJSON';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import PaymentOfOrderRefunds from '../../../modals/payment-of-order-refunds';
const TabsRefunds = (props:any) => {
  const navigate = useNavigate();
  const [customerDetails, setCustomerDetails] = useState<any>();
  const [orderRefunds, setOrderRefunds] = useState(false);
  const [filterValue, setFilterValue] = useState<any>(getKey(listCustomerRefundDetails.filter) || refundListJSON[0]);
  const [loading, setLoading] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [enableSelectAll, setEnableSelectAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [customer, setCustomer] = useState<any>(
    localStorage.getItem('customer')
  );
  const [refundList, setRefundList] = useState([]);
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [totalRecords, setTotalRecords] = useState(0);
  useEffect(() => {
    (async () => {
      setLoading(true);
      let details: any = props.customerId || localStorage.getItem('CustomerId');
      setCustomerDetails(props.customerId ? props.customerId : localStorage.getItem('CustomerId'));
      await fetchRefundList(page, pageLimit, details, 0, search);
      setLoading(false);
    })();
  }, []);
  const fetchRefundList = async (
    pageNo: number,
    limit: number,
    buyerId: string,
    listType?: number,
    search?: string
  ) => {
    setLoading(true);
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'name',
      sortOrder: 1,
      needCount: pageNo == 1,
      buyer: buyerId,
      listType: listType,
      searchTerm: search,
    };
    let apiService = new APICallService(buyer.refundBuyerOrderList, params);
    let response = await apiService.callAPI();
    if (pageNo === 1) {
      setTotalRecords(response.total);
    } else {
      let prevTotal = totalRecords;
      setTotalRecords(prevTotal);
    }
    setRefundList(response.records);
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    await fetchRefundList(
      val,
      pageLimit,
      customerDetails,
      filterValue.value,
      search
    );
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    await fetchRefundList(
      val + 1,
      pageLimit,
      customerDetails,
      filterValue.value,
      search
    );
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    await fetchRefundList(
      val - 1,
      pageLimit,
      customerDetails,
      filterValue.value,
      search
    );
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    setPageLimit(event.target.value);
    await fetchRefundList(
      1,
      event.target.value,
      customerDetails,
      filterValue.value,
      search
    );
  };
  const handleChecked = (id: string, data: any, index: number) => {
    let temp = [...selectedId];
    let tempData = [...selectedData];
    if (temp.length) {
      if (temp.includes(id)) {
        temp = temp.filter((item) => item != id);
        tempData = tempData.filter((item) => item._id != id);
      } else {
        temp.push(id);
        tempData.push(data);
      }
    } else {
      temp.push(id);
      tempData.push(data);
    }
    setSelectedId(temp);
    setSelectedData(tempData);
    setSelected(temp.length == totalRecords);
  };
  const handleRefund = (data: any) => {
    // setOrderRefunds(true);
    let temp: any = { refundVal: JSON.parse(customer).user };
    navigate('/customers/refunds-to-customers/make-refund', {
      state: { selectedData: [data], customerDetails: temp },
    });
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>
          You are allowed to issue a refund for a delivered or cancelled order
        </span>
      </Popover.Body>
    </Popover>
  );
  const markAsRefund = async () => {
    if (selectedData.length) {
      setRefundLoading(true);
      let temp: any = [];
      selectedData.map((val: any) => {
        temp.push({
          orderId: val._id,
          paymentMode: OrderCash,
          amount: val.payment.adjustmentCharge
            ? val.payment.adjustmentCharge
            : val.payment.receivedCharge,
        });
      });
      let params = {
        refundData: temp,
        buyerId: customerDetails,
      };
      let apiService = new APICallService(buyer.updateRefundStatus, params);
      let response = await apiService.callAPI();
      // setRefundList(response.records);
      let refundTemp = [...refundList];
      refundTemp.map((val: any) => {
        if (selectedId.includes(val._id)) {
          val.payment.adjustmentTransactions = [
            {
              completed: true,
              completedAt: new Date(),
            },
          ];
        }
        return val;
      });
      setSelectLoading(true);
      setSelectedId([]);
      setSelected(false);
      setSelectedData([]);
      setEnableSelectAll(false);
      setSelectLoading(false);
      setRefundList(refundTemp);
      setRefundLoading(false);
      setOrderRefunds(false);
    }
  };
  const handleFilter = async (event: any) => {
    setFilterValue(event);
    setKey(listCustomerRefundDetails.filter, event, false);
    setPage(1);
    await fetchRefundList(1, pageLimit, customerDetails, event.value, search);
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    await fetchRefundList(
      1,
      pageLimit,
      customerDetails,
      filterValue.value,
      value
    );
  };
  return (
    <>
      <Card className="bg-light border mb-6">
        {orderRefunds ? (
          <PaymentOfOrderRefunds
            show={orderRefunds}
            onHide={() => setOrderRefunds(false)}
            selectedData={selectedData}
            state={customer}
            markAsRefund={markAsRefund}
            refundLoading={refundLoading}
          />
        ) : (
          <></>
        )}
        <Card.Body className="px-7">
          <Row className="align-items-center d-flex flex-end">
            {/* <Col
              md
              className="mb-md-0 mb-5"
            >
              <div className="d-flex align-items-center mw-lg-315px position-relative me-lg-4">
                <KTSVG
                  path="/media/icons/duotune/general/gen021.svg"
                  className="svg-icon-3 position-absolute ms-4"
                />
                <input
                  type="text"
                  id="kt_filter_search"
                  className="form-control form-control-custom bg-white min-h-60px ps-12 text-dark"
                  placeholder="Search ..."
                  value={search}
                  onChange={(event: any) => {
                    handleSearch(event.target.value.trimStart());
                  }}
                />
              </div>
            </Col> */}
            <Col md="auto">
              <div className="d-flex align-items-center">
                <label
                  htmlFor=""
                  className="fs-16 fw-500"
                >
                  Filter orders
                </label>
                <div className="min-w-lg-300px mw-lg-300px ms-6">
                  <CustomSelect
                    backgroundColor={'white'}
                    options={refundListJSON}
                    value={filterValue}
                    default={filterValue}
                    onChange={(event: any) => {
                      handleFilter(event);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="border border-r10px">
        <Card.Body className="p-0">
          <div className="table-responsive">
            <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
              <thead>
                <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                  {enableSelectAll ? (
                    <th
                      className="min-w-100px"
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={1}
                    >
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input h-30px w-30px"
                          type="checkbox"
                          value="1"
                          checked={
                            selectLoading ||
                            (selectedId.length == totalRecords && selected)
                          }
                        />
                        <label className="form-check-label fs-16 fw-600 text-dark">
                          Select all
                        </label>
                      </div>
                    </th>
                  ) : (
                    <th className="min-w-md-175px min-w-150px">
                      Order Date & ID
                    </th>
                  )}
                  <th className="min-w-100px">
                    Total order <br /> value
                  </th>
                  <th className="min-w-120px">
                    Total refund <br /> value
                  </th>
                  <th className="min-w-120px">Refund reason</th>
                  <th className="min-w-120px">Status</th>
                  <th className="min-w-120px">Refund Status</th>
                  <th className="min-w-150px text-end"></th>
                </tr>
              </thead>
              <tbody className="fs-15 fw-600">
                {loading ? (
                  <>
                    <tr>
                      <td colSpan={4}>
                        <div className="d-flex justify-content-center">
                          <Loader loading={loading} />
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {refundList.length > 0 ? (
                      refundList.map((order: any, index: number) => (
                        <>
                          <tr key={index}>
                            {/* <td>
                              <span className="text-dark d-block">
                                {Method.convertDateToDDMMYYYYHHMM(
                                  order._createdAt,
                                  '-'
                                )}
                              </span>
                              <span className="text-gray d-block">
                                {order.refKey}
                              </span>
                            </td> */}
                            {enableSelectAll ? (
                              <td>
                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                  {order.payment.adjustmentTransactions
                                    .length ? (
                                    <></>
                                  ) : (
                                    <input
                                      className="form-check-input h-30px w-30px"
                                      type="checkbox"
                                      value={order._id}
                                      checked={selectedId.includes(order._id)}
                                      onChange={() => {
                                        handleChecked(order._id, order, index);
                                      }}
                                    />
                                  )}
                                  <div className="d-flex align-items-start flex-column ms-2">
                                    <div className="d-flex flex-row">
                                      <span className="text-dark d-block">
                                        {Method.convertDateToDDMMYYYYHHMM(
                                          order._createdAt,
                                          '-'
                                        )}
                                      </span>
                                    </div>
                                    <span className="text-gray d-block">
                                      {order.refKey}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <td className="fs-15 fw-500">
                                <div className="d-flex align-items-start flex-column">
                                  <div className="d-flex flex-row">
                                    <span className="text-dark d-block">
                                      {Method.convertDateToDDMMYYYYHHMM(
                                        order._createdAt,
                                        '-'
                                      )}
                                    </span>
                                  </div>
                                  <span className="text-gray d-block">
                                    {order.refKey}
                                  </span>
                                </div>
                              </td>
                            )}
                            <td>
                              {order.status === OrderCancelled
                                ? 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.receivedCharge
                                  )
                                : 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.adjustmentCharge
                                  )}
                            </td>
                            <td>
                              {order.status === OrderCancelled
                                ? 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.receivedCharge
                                  )
                                : 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.adjustmentCharge
                                  )}
                            </td>
                            <td>
                              <span className="badge badge-light fs-15 fw-600 border-r23px text-dark p-3 px-4">
                                {order.status === OrderCancelled
                                  ? 'Order Cancelled'
                                  : 'Order Modified'}
                              </span>
                            </td>
                            <td>
                              {order.status === OrderCancelled ? (
                                <span className="badge bg-light-danger fs-14 fw-600 text-dark p-3 px-4">
                                  Order Cancelled
                                </span>
                              ) : (
                                ''
                              )}
                              {order.status === OrderSubmitted ||
                              order.status === OrderProcessed ||
                              order.status === OrderOutForDelivery ? (
                                <span className="badge bg-fff4d9 fs-14 fw-600 text-dark p-3 px-4">
                                  {order.status === OrderSubmitted
                                    ? 'Order Placed'
                                    : ''}
                                  {order.status === OrderProcessed
                                    ? 'Order In-Progress'
                                    : ''}
                                  {order.status === OrderOutForDelivery
                                    ? 'Order Out-For-Delivery'
                                    : ''}
                                </span>
                              ) : (
                                <></>
                              )}{' '}
                              {order.status === OrderDelivered ? (
                                <span className="badge bg-light-success fs-14 fw-600 text-dark p-3 px-4">
                                  {' '}
                                  {order.status === OrderDelivered
                                    ? 'Order Delivered'
                                    : ''}
                                </span>
                              ) : (
                                ''
                              )}
                            </td>
                            <td>
                              {order.payment.adjustmentTransactions.length ? (
                                <span className="badge bg-e5f6de fs-14 fw-600 text-dark p-3 px-4">
                                  Refunded on{' '}
                                  {Method.convertDateToDDMMYYYY(
                                    order.payment.adjustmentTransactions[0]
                                      .completedAt
                                  )}
                                </span>
                              ) : (
                                // <span className="badge bg-fff4d9 fs-14 fw-600 text-dark p-3 px-4">
                                //   Not refunded
                                // </span>
                                <>
                                  {' '}
                                  {order.status === OrderCancelled ||
                                  order.status === OrderDelivered ? (
                                    <Button
                                      className="fs-14 fw-600"
                                      variant="light-primary"
                                      onClick={() => {
                                        handleRefund(order);
                                      }}
                                    >
                                      Make a payment
                                    </Button>
                                  ) : (
                                    <OverlayTrigger
                                      trigger="hover"
                                      placement="bottom"
                                      overlay={popover}
                                    >
                                      <Button
                                        className="fs-14 fw-600"
                                        variant="light-primary"
                                      >
                                        Make a payment
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                                </>
                              )}
                            </td>
                            <td className="text-end">
                              <Button
                                className="fs-14 fw-600"
                                onClick={() =>
                                  navigate('/orders/order-details', {
                                    state: order._id,
                                  })
                                }
                              >
                                View details
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                            No Data found
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}{' '}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
      {!loading && refundList && refundList.length ? (
        <Pagination
          totalRecords={totalRecords}
          currentPage={page}
          handleCurrentPage={handleCurrentPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          handlePageLimit={handlePageLimit}
          pageLimit={pageLimit}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default TabsRefunds;
