import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { KTSVG } from "../../../../_admin/helpers";
import { CustomSelectTable } from "../../../custom/Select/CustomSelectTable";
import ThreeDotMenu from "../../../../_admin/assets/media/svg_uTrade/three-dot.svg";
import Loader from "../../../../Global/loader";
import { AdvertisementUpcoming, HomePage } from "../../../../utils/constants";
import Method from "../../../../utils/methods";
import Pagination from "../../../../Global/pagination";
import { listAdvertisement } from "../../../../utils/storeString";
import { setKey } from "../../../../Global/history";
const Upcoming = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      {/* begin::Search */}
      {/* <div className="position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-13"
        />
        <input
          type="text"
          className="form-control form-control-custom borde-r8px bg-light w-375px ps-11"
          name="Search Team"
          onChange={(event: any) => {
            props.handleSearch(event, AdvertisementUpcoming);
          }}
          placeholder="Search by seller name…"
        />
      </div> */}
      {/* end::Search */}
      <div className="table-responsive">
        <table className="table table-rounded table-row-bordered align-middle gy-4 mb-0">
          <thead>
            <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
              <th className="min-w-125px">Banner</th>
              <th className="min-w-150px">Seller</th>
              <th className="min-w-160px">Ad placement</th>
              <th className="min-w-160px">Start date</th>
              <th className="min-w-160px">Cost per day</th>
              <th className="min-w-175px"></th>
            </tr>
          </thead>
          <tbody>
            {props.fetchLoading ? (
              <>
                <tr>
                  <td colSpan={5}>
                    <div className="d-flex justify-content-center">
                      <Loader loading={props.fetchLoading} />
                    </div>
                  </td>{" "}
                </tr>
              </>
            ) : (
              <>
                {props.advertisement.length ? (
                  <>
                    {props.advertisement.map(
                      (advertiseVal: any, index: number) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <img
                                  className="img-fluid mw-87px mh-50px border-r6px object-fit-contain"
                                  src={advertiseVal.image}
                                  alt=""
                                />
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {advertiseVal.business.ownerName}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {advertiseVal.placement === HomePage
                                    ? "Home page"
                                    : "Category page"}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {" "}
                                  {Method.convertDateToDDMMYYYY(
                                    advertiseVal.startDate
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  TSh{" "}
                                  {Method.getGeneralizedAmount(
                                    advertiseVal.amount
                                  )}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex flex-row flex-sm-nowrap justify-content-end">
                                  <Button
                                    variant="primary"
                                    className="btn-active-light-primary fs-14 fw-600"
                                    onClick={() => {
                                      let currentScrollY = window.scrollY;
                                      setKey(
                                        listAdvertisement.scrollPosition,
                                        currentScrollY
                                      );
                                      navigate(
                                        "/advertisement/view-advertisement/advertising-details",
                                        {
                                          state: {
                                            status: AdvertisementUpcoming,
                                            id: advertiseVal._id,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    View details
                                  </Button>
                                  {/* <CustomSelectTable
                                    placeholder={
                                      <img
                                        className="img-fluid"
                                        width={18}
                                        height={5}
                                        src={ThreeDotMenu}
                                        alt=""
                                      />
                                    }
                                    backgroundColor="white"
                                  /> */}
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className="d-flex justify-content-center">
                        <span className="fs-15 fw-500">No Data found</span>
                      </div>
                    </td>{" "}
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {props.fetchLoading ? (
        <></>
      ) : (
        <>
          {props.advertisement.length ? (
            <>
              <Pagination
                totalRecords={props.total}
                currentPage={props.currentPage}
                handleCurrentPage={(event: any) => {
                  props.handleCurrentPage(event, AdvertisementUpcoming);
                }}
                handleNextPage={(event: any) => {
                  props.handleNextPage(event, AdvertisementUpcoming);
                }}
                handlePreviousPage={(event: any) => {
                  props.handlePreviousPage(event, AdvertisementUpcoming);
                }}
                pageLimit={parseInt(props.pageLimit)}
                handlePageLimit={(event: any) => {
                  props.handlePageLimit(event, AdvertisementUpcoming);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default Upcoming;
