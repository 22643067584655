import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Col,
  NavDropdown,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../../Global/loader';
import edit from '../../../../_admin/assets/media/edit-round-large.png';
import Request from '../../../../_admin/assets/media/select/request.png';
import Email from '../../../../_admin/assets/media/svg_uTrade/email.svg';
import PhoneCall from '../../../../_admin/assets/media/svg_uTrade/phone-call.svg';
import Verified from '../../../../_admin/assets/media/svg_uTrade/verified.svg';
import APICallService from '../../../../api/apiCallService';
import { master, seller } from '../../../../api/apiEndPoints';
import { sellerJSON } from '../../../../api/apiJSON/seller';
import {
  IBusinessCertificates,
  IBusinessType,
  ICategories,
  IGetProfile,
} from '../../../../types/responseIndex';
import {
  Accepted,
  FinanceManager,
  FulfillmentManager,
  InfoRequired,
  NewRequests,
  Rejected,
  UnderReview,
} from '../../../../utils/constants';
import Method from '../../../../utils/methods';
import { sellerToast } from '../../../../utils/toast';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import AcceptBusiness from '../../../modals/accept-business';
import InfoRequiredModal from '../../../modals/info-required';
import RejectBusiness from '../../../modals/reject-business';
import BusinessKycDetails from './businessDetails/business-kyc-details';
import Notification from '../../../notification/notification';
import Catalogue from './Catalogue/catalogue';
import TabsAdvertisements from './fianace-tabs/seller-advertisements';
import SellerInventory from './fianace-tabs/seller-Inventory';
import SellerGoodsRequest from './fianace-tabs/seller-goods-requests';
import SellerInventoryHistory from './fianace-tabs/seller-inventory-history';
import SellerStockDetails from './fianace-tabs/seller-stock-details';
import { useAuth } from '../../auth';
import { kycOptions } from '../../../../utils/staticJSON';
import { getKey, setKey } from '../../../../Global/history';
import { sellerProfile } from '../../../../utils/storeString';
import e from 'express';
const SellerProfile = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const location: any = useLocation();
  const [editSellerProfile, setEditSellerProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [profileData, setProfileData] = useState<IGetProfile | undefined>(
    undefined
  );
  const [infoRequire, setInfoRequire] = useState(false);
  const [infoAccept, setInfoAccept] = useState(false);
  const [infoReject, setInfoReject] = useState(false);
  const [editData, setEditData] = useState<IGetProfile | undefined>(undefined);
  const [category, setCategory] = useState<ICategories | undefined>(undefined);
  const [businessTypes, setBusinessType] = useState<IBusinessType | undefined>(
    undefined
  );
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [showExpiry, setShowExpiry] = useState(false);
  const [selectedSubTab, setSelectedSubTab] = useState<number>(0);
  const [businessCertificate, setBusinessCertificate] = useState<
    IBusinessCertificates | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      setLoading(true);
      let currentTabTemp: any = {};
      console.log('hii',currentTab,getKey(sellerProfile.currentTab))
      if (getKey(sellerProfile.currentTab)) {
        let temp: any = getKey(sellerProfile.currentTab);
        switch (temp?.toString()) {
          case '0':
            currentTabTemp = {
              name: 'Business & KYC details',
              content: (
                <BusinessKycDetails
                  handleAddressChange={handleAddressChange}
                  currentIndex={currentIndex}
                  show={editSellerProfile}
                  profileData={profileData}
                  editData={editData}
                  category={category}
                  businessTypes={businessTypes}
                  onDetailsChange={(name: string, value: string) => {
                    handleDetailsChange(name, value);
                  }}
                  onCategorySelect={(event: any) => {
                    handleCategorySelect(event);
                  }}
                  onBusinessTypesSelect={(event: any) => {
                    handleBusinessType(event);
                  }}
                  businessCertificate={businessCertificate}
                  onCertificateChange={handleCertificate}
                  onPendingCertificateChange={handlePendingCertificate}
                />
              ),
              value: 0,
            };
            break;
          case '1':
            currentTabTemp = {
              name: 'Catalogue',
              content: <Catalogue />,
              value: 1,
            };
            break;
          case '2':
            currentTabTemp = {
              name: (
                <>
                  <NavDropdown
                    title="Inventory"
                    id="nav-dropdown"
                    className="custom_dropdown"
                  >
                    <NavDropdown.Item
                      eventKey="1"
                      className="fs-14 fw-500"
                      onClick={() => {
                        handleSelect(1);
                      }}
                    >
                      Seller Inventory
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey="2"
                      className="fs-14 fw-500"
                      onClick={() => {
                        handleSelect(2);
                      }}
                    >
                      History of inventory
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey="3"
                      className="fs-14 fw-500"
                      onClick={() => {
                        handleSelect(3);
                      }}
                    >
                      Stock details
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ),
              content: inventoryContent,
              value: 2,
            };
            break;
          case '4':
            currentTabTemp = {
              name: (
                <NavDropdown
                  title="Requests"
                  id="nav-dropdown"
                  className="custom_dropdown"
                >
                  <NavDropdown.Item
                    eventKey="1"
                    className="fs-14 fw-500"
                    onClick={() => {
                      handleSelect(4);
                    }}
                  >
                    Goods requests
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
            eventKey="2"
            className="fs-14 fw-500"
          >
            Return requests
          </NavDropdown.Item> */}
                </NavDropdown>
              ),
              content: requestsContent,
              value: 4,
            };
            break;
          case '5':
            currentTabTemp = {
              name: 'Advertisements',
              content: <TabsAdvertisements businessId={location.state} />,
              value: 5,
            };
            break;
        }
      } else {
        currentTabTemp = {
          name: 'Business & KYC details',
          content: (
            <BusinessKycDetails
              handleAddressChange={handleAddressChange}
              currentIndex={currentIndex}
              show={editSellerProfile}
              profileData={profileData}
              editData={editData}
              category={category}
              businessTypes={businessTypes}
              onDetailsChange={(name: string, value: string) => {
                handleDetailsChange(name, value);
              }}
              onCategorySelect={(event: any) => {
                handleCategorySelect(event);
              }}
              onBusinessTypesSelect={(event: any) => {
                handleBusinessType(event);
              }}
              businessCertificate={businessCertificate}
              onCertificateChange={handleCertificate}
              onPendingCertificateChange={handlePendingCertificate}
            />
          ),
          value: 0,
        };
      }
      await fetchProfileData();
      setCurrentTab(currentTabTemp);
      setLoading(false);
      await fetchCategoryData();
      await fetchBusinessTypesData();
      await fetchCertificatesData();
    })();
  }, []);
  // useEffect(() => {
  //   (async () => {
  //     setCurrentTab({
  //       name: 'Business & KYC details',
  //       content: (
  //         <BusinessKycDetails
  //           handleAddressChange={handleAddressChange}
  //           currentIndex={currentIndex}
  //           show={editSellerProfile}
  //           profileData={profileData}
  //           editData={editData}
  //           category={category}
  //           businessTypes={businessTypes}
  //           onDetailsChange={(name: string, value: string) => {
  //             handleDetailsChange(name, value);
  //           }}
  //           onCategorySelect={(event: any) => {
  //             handleCategorySelect(event);
  //           }}
  //           onBusinessTypesSelect={(event: any) => {
  //             handleBusinessType(event);
  //           }}
  //           businessCertificate={businessCertificate}
  //           onCertificateChange={handleCertificate}
  //           onPendingCertificateChange={handlePendingCertificate}
  //           handleShowExpiry={(index: number) => {
  //             handleShowExpiry(index);
  //           }}
  //           handleHideExpiry={handleHideExpiry}
  //           handleExpirySelect={(value: string, index: number): void =>
  //             handleExpirySelect(value, index)
  //           }
  //           showExpiry={showExpiry}
  //         />
  //       ),
  //       value: 0,
  //     });
  //   })();
  // }, [editSellerProfile]);
  useEffect(() => {
    (async () => {
      let currentTabTemp: any = {};
      if (getKey(sellerProfile.currentTab)) {
        let temp: any = getKey(sellerProfile.currentTab);
        switch (temp?.toString()) {
          case '0':
            currentTabTemp = {
              name: 'Business & KYC details',
              content: (
                <BusinessKycDetails
                  handleAddressChange={handleAddressChange}
                  currentIndex={currentIndex}
                  show={editSellerProfile}
                  profileData={profileData}
                  editData={editData}
                  category={category}
                  businessTypes={businessTypes}
                  onDetailsChange={(name: string, value: string) => {
                    handleDetailsChange(name, value);
                  }}
                  onCategorySelect={(event: any) => {
                    handleCategorySelect(event);
                  }}
                  onBusinessTypesSelect={(event: any) => {
                    handleBusinessType(event);
                  }}
                  businessCertificate={businessCertificate}
                  onCertificateChange={handleCertificate}
                  onPendingCertificateChange={handlePendingCertificate}
                />
              ),
              value: 0,
            };
            break;
          case '1':
            currentTabTemp = {
              name: 'Catalogue',
              content: <Catalogue />,
              value: 1,
            };
            break;
          case '2':
            currentTabTemp = {
              name: (
                <>
                  <NavDropdown
                    title="Inventory"
                    id="nav-dropdown"
                    className="custom_dropdown"
                  >
                    <NavDropdown.Item
                      eventKey="1"
                      className="fs-14 fw-500"
                      onClick={() => {
                        handleSelect(1);
                      }}
                    >
                      Seller Inventory
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey="2"
                      className="fs-14 fw-500"
                      onClick={() => {
                        handleSelect(2);
                      }}
                    >
                      History of inventory
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey="3"
                      className="fs-14 fw-500"
                      onClick={() => {
                        handleSelect(3);
                      }}
                    >
                      Stock details
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ),
              content: inventoryContent,
              value: 2,
            };
            break;
          case '4':
            currentTabTemp = {
              name: (
                <NavDropdown
                  title="Requests"
                  id="nav-dropdown"
                  className="custom_dropdown"
                >
                  <NavDropdown.Item
                    eventKey="1"
                    className="fs-14 fw-500"
                    onClick={() => {
                      handleSelect(4);
                    }}
                  >
                    Goods requests
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
            eventKey="2"
            className="fs-14 fw-500"
          >
            Return requests
          </NavDropdown.Item> */}
                </NavDropdown>
              ),
              content: requestsContent,
              value: 4,
            };
            break;
          case '5':
            currentTabTemp = {
              name: 'Advertisements',
              content: <TabsAdvertisements businessId={location.state} />,
              value: 5,
            };
            break;
        }
      } else {
        currentTabTemp = {
          name: 'Business & KYC details',
          content: (
            <BusinessKycDetails
              handleAddressChange={handleAddressChange}
              currentIndex={currentIndex}
              show={editSellerProfile}
              profileData={profileData}
              editData={editData}
              category={category}
              businessTypes={businessTypes}
              onDetailsChange={(name: string, value: string) => {
                handleDetailsChange(name, value);
              }}
              onCategorySelect={(event: any) => {
                handleCategorySelect(event);
              }}
              onBusinessTypesSelect={(event: any) => {
                handleBusinessType(event);
              }}
              businessCertificate={businessCertificate}
              onCertificateChange={handleCertificate}
              onPendingCertificateChange={handlePendingCertificate}
            />
          ),
          value: 0,
        };
      }
      if (currentTabTemp.value === 0) {
        await fetchProfileData();
      }
      setCurrentTab(currentTabTemp);
    })();
  }, [loading]);
  // useEffect(() => {
  //   (async () => {
  //     setCurrentTab({
  //       name: 'Business & KYC details',
  //       content: (
  //         <BusinessKycDetails
  //           handleAddressChange={handleAddressChange}
  //           currentIndex={currentIndex}
  //           show={editSellerProfile}
  //           profileData={profileData}
  //           editData={editData}
  //           category={category}
  //           businessTypes={businessTypes}
  //           onDetailsChange={(name: string, value: string) => {
  //             handleDetailsChange(name, value);
  //           }}
  //           onCategorySelect={(event: any) => {
  //             handleCategorySelect(event);
  //           }}
  //           onBusinessTypesSelect={(event: any) => {
  //             handleBusinessType(event);
  //           }}
  //           businessCertificate={businessCertificate}
  //           onCertificateChange={handleCertificate}
  //           onPendingCertificateChange={handlePendingCertificate}
  //           handleShowExpiry={(index: number) => {
  //             handleShowExpiry(index);
  //           }}
  //           handleHideExpiry={handleHideExpiry}
  //           handleExpirySelect={(value: string, index: number): void =>
  //             handleExpirySelect(value, index)
  //           }
  //           showExpiry={showExpiry}
  //         />
  //       ),
  //       value: 0,
  //     });
  //   })();
  // }, [editData]);
  // useEffect(() => {
  //   (async () => {
  //     setCurrentTab({
  //       name: 'Business & KYC details',
  //       content: (
  //         <BusinessKycDetails
  //           handleAddressChange={handleAddressChange}
  //           currentIndex={currentIndex}
  //           show={editSellerProfile}
  //           profileData={profileData}
  //           editData={editData}
  //           category={category}
  //           businessTypes={businessTypes}
  //           onDetailsChange={(name: string, value: string) => {
  //             handleDetailsChange(name, value);
  //           }}
  //           onCategorySelect={(event: any) => {
  //             handleCategorySelect(event);
  //           }}
  //           onBusinessTypesSelect={(event: any) => {
  //             handleBusinessType(event);
  //           }}
  //           businessCertificate={businessCertificate}
  //           onCertificateChange={handleCertificate}
  //           onPendingCertificateChange={handlePendingCertificate}
  //           handleShowExpiry={(index: number) => {
  //             handleShowExpiry(index);
  //           }}
  //           handleHideExpiry={handleHideExpiry}
  //           handleExpirySelect={(value: string, index: number): void =>
  //             handleExpirySelect(value, index)
  //           }
  //           showExpiry={showExpiry}
  //         />
  //       ),
  //       value: 0,
  //     });
  //   })();
  // }, [showExpiry]);
  const fetchProfileData = async () => {
    let data: any = '';
    if (localStorage.getItem('profile')) {
      data = localStorage.getItem('profile');
    } else {
      localStorage.setItem('profile', location.state);
      data = location.state;
    }
    let apiService = new APICallService(seller.sellerProfile, data);
    let response = await apiService.callAPI();
    if (response) {
      setProfileData(JSON.parse(JSON.stringify(response)));
      setEditData(JSON.parse(JSON.stringify(response)));
    }
  };
  const handleShowExpiry = (index: number) => {
    setCurrentIndex(index);
    setShowExpiry(true);
  };
  const handleExpirySelect = (value: string, index: number) => {
    let data: any = { ...editData };
    data.business.certificates[index].expiry =
      moment(value).format('YYYY-MM-DD');
    data.business.certificates[index].expiryCalendar = value;
    data.business.certificates[index].isChanged = true;
    setEditData(data);
    setShowExpiry(false);
  };
  const handleHideExpiry = () => {
    setShowExpiry(false);
  };
  useEffect(() => {
    if (
      !editData?.business.name.length ||
      !editData?.business.ownerName.length ||
      !editData?.business.phone.length ||
      !editData?.business.email.length ||
      !editData?.business.address.length ||
      !editData?.business.about.length ||
      !editData?.business.categories.length ||
      !editData?.business.certificates.length
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [editData]);
  const fetchCategoryData = async () => {
    let apiService = new APICallService(master.categoryList, {
      sortKey: 'title',
      sortOrder: 1,
      categoriesDepth: 1,
    });
    let response = await apiService.callAPI();
    if (response) {
      setCategory(response.records);
    }
  };
  const fetchBusinessTypesData = async () => {
    let apiService = new APICallService(master.listBusinessTypes);
    let response = await apiService.callAPI();
    if (response) {
      setBusinessType(response.records);
    }
  };
  const fetchCertificatesData = async () => {
    let apiService = new APICallService(master.listCertificate);
    let response = await apiService.callAPI();
    if (response) {
      setBusinessCertificate(response.records);
    }
  };
  const handleDetailsChange = async (name: any, value: string) => {
    let editDataTemp: any = { ...editData };
    editDataTemp.business[name] = value.trimStart();
    setEditData(editDataTemp);
  };
  const handleAddressChange = async (
    value: string,
    lat: string,
    lng: string
  ) => {
    let editDataTemp: any = { ...editData };
    editDataTemp.business['address'] = value.trimStart();
    editDataTemp.business['lat'] = lat;
    editDataTemp.business['lng'] = lng;
    setEditData(editDataTemp);
  };
  const handleCategorySelect = async (event: any) => {
    let temp: any = { ...editData };
    let newCertificate: any = [];
    let certificate: any = businessCertificate;
    if (event.length) {
      let data: any = [];
      event.map((eventVal: any) => {
        certificate.map((businessVal: any) => {
          if (businessVal.categories.length) {
            businessVal.categories.map((catVal: any) => {
              if (eventVal._id === catVal._id) {
                newCertificate.push(businessVal);
              }
            });
            temp.business.certificates.map((tempVal: any, index: number) => {
              if (tempVal.reference._id === businessVal._id) {
                temp.business.certificates.splice(index, 1);
              }
            });
          } else {
            temp.business.certificates.map((tempVal: any, index: number) => {
              if (tempVal.reference._id === businessVal._id) {
                temp.business.certificates.splice(index, 1);
              }
            });
            newCertificate.push(businessVal);
          }
        });
        data.push({
          reference: {
            _id: eventVal._id,
            title: eventVal.value,
            image: eventVal.image,
          },
        });
      });
      temp.business.categories = data;
    } else {
      certificate.map((businessVal: any) => {
        temp.business.certificates.map((tempVal: any, index: number) => {
          if (tempVal.reference._id === businessVal._id) {
            temp.business.certificates.splice(index, 1);
          }
        });
      });
    }
    let filteredCertificate = newCertificate.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((v2: any) => v2._id === v._id) === i
    );
    let data: any = [];
    filteredCertificate.map((filterVal: any) => {
      if (
        !temp.business.certificates.some(
          (tempVal: any) => tempVal.reference._id === filterVal._id
        )
      ) {
        data.push(filterVal);
      }
    });
    temp.pendingCertificates = data;
    setEditData(temp);
  };
  const handleBusinessType = async (event: any) => {
    let temp: any = { ...editData };
    temp.business.types = [
      {
        reference: { _id: event._id, title: event.value, image: event.image },
      },
    ];
    setEditData(temp);
  };
  const handleCertificate = async (event: any, index: number) => {
    let temp: any = { ...editData };
    const selectedFiles = event.target.files;
    if (!selectedFiles) return;
    else {
      temp.business.certificates[index] = {
        ...temp.business.certificates[index],
        document: {
          name: selectedFiles?.[0].name,
          url: URL.createObjectURL(selectedFiles?.[0]),
        },
        isChanged: true,
        image: URL.createObjectURL(selectedFiles?.[0]),
        imageReader: selectedFiles?.[0],
      };
    }
    setEditData(temp);
  };
  const handlePendingCertificate = async (
    event: any,
    index: number,
    reference: any
  ) => {
    let temp: any = { ...editData };
    const selectedFiles = event.target.files;
    if (!selectedFiles) return;
    else {
      temp.business.certificates.push({
        document: {
          name: selectedFiles?.[0].name,
          url: URL.createObjectURL(selectedFiles?.[0]),
        },
        reference: {
          _id: reference._id,
          title: reference.title,
          image: reference.image,
          categories: reference.categories,
          type: reference.type ? reference.type : '',
        },
        expiry: '',
        isAdded: true,
        isChanged: true,
        image: URL.createObjectURL(selectedFiles?.[0]),
        imageReader: selectedFiles?.[0],
      });
      temp.pendingCertificates.splice(index, 1);
    }
    setEditData(temp);
  };
  const onProfileChange = async (event: any) => {
    let temp: any = { ...editData };
    const selectedFiles = event.target.files;
    if (!selectedFiles) return;
    else {
      temp.business.image = URL.createObjectURL(selectedFiles?.[0]);
      temp.business.imageReader = selectedFiles?.[0];
    }
    setEditData(temp);
  };
  const onSaveChanges = async () => {
    setSaveLoading(true);
    let apiService = new APICallService(
      seller.sellerProfileUpdate,
      sellerJSON.sellerProfileUpdate({
        profile: editData?.business,
        pendingCertificates: editData?.business.pendingCertificates,
      }),
      {
        id: location.state,
      }
    );
    let response = await apiService.callAPI();
    if (response) {
      setEditSellerProfile(false);
      setProfileData(response);
      setEditData(response);
      setShowVerify(false);
      toast.success(sellerToast.updateProfile);
    }
    setSaveLoading(false);
  };
  const [inventoryContent, setInventoryContent] = useState<any>(<SellerInventory businessId={location.state} />);
  const [requestsContent, setRequestsContent] = useState<any>();
  const [paymentsContent, setPaymentsContent] = useState<any>();
  useEffect(() => {
    (async () => {
      setCurrentTab({
        name: currentTab.name,
        content: inventoryContent,
        value: currentTab.value,
      });
    })();
  }, [inventoryContent]);
  useEffect(() => {
    (async () => {
      setCurrentTab({
        name: currentTab.name,
        content: requestsContent,
        value: currentTab.value,
      });
    })();
  }, [requestsContent]);
  // useEffect(() => {
  //   (async () => {
  //     setCurrentTab({
  //       name: currentTab.name,
  //       content: paymentsContent,
  //       value: currentTab.value,
  //     });
  //   })();
  // }, [paymentsContent]);
  const handleSelect = (eventKey: any) => {
    setSelectedSubTab(eventKey);
    switch (eventKey) {
      case 1:
        setInventoryContent(<SellerInventory businessId={location.state} />);
        break;
      case 2:
        setInventoryContent(
          <SellerInventoryHistory businessId={location.state} />
        );
        break;
      case 3:
        setInventoryContent(<SellerStockDetails businessId={location.state} />);
        break;
      case 4:
        setInventoryContent(<SellerGoodsRequest businessId={location.state} />);
        break;
      default:
        break;
    }
  };
  const [tab, setTab] = useState([
    {
      name: 'Business & KYC details',
      content: (
        <BusinessKycDetails
          handleAddressChange={handleAddressChange}
          currentIndex={currentIndex}
          show={editSellerProfile}
          profileData={profileData}
          editData={editData}
          category={category}
          businessTypes={businessTypes}
          onDetailsChange={(name: string, value: string) => {
            handleDetailsChange(name, value);
          }}
          onCategorySelect={(event: any) => {
            handleCategorySelect(event);
          }}
          onBusinessTypesSelect={(event: any) => {
            handleBusinessType(event);
          }}
          businessCertificate={businessCertificate}
          onCertificateChange={handleCertificate}
          onPendingCertificateChange={handlePendingCertificate}
        />
      ),
      value: 0,
    },
    { name: 'Catalogue', content: <Catalogue />, value: 1 },
    {
      name: (
        <>
          <NavDropdown
            title="Inventory"
            id="nav-dropdown"
            className="custom_dropdown"
          >
            <NavDropdown.Item
              eventKey="1"
              className="fs-14 fw-500"
              onClick={() => {
                handleSelect(1);
              }}
            >
              Seller Inventory
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="2"
              className="fs-14 fw-500"
              onClick={() => {
                handleSelect(2);
              }}
            >
              History of inventory
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="3"
              className="fs-14 fw-500"
              onClick={() => {
                handleSelect(3);
              }}
            >
              Stock details
            </NavDropdown.Item>
          </NavDropdown>
        </>
      ),
      content: inventoryContent,
      value: 2,
    },
    // {
    //   name: 'Orders',
    //   content: <OrdersTab businessId={location.state} />,
    //   value: 3,
    // },
    {
      name: (
        <NavDropdown
          title="Requests"
          id="nav-dropdown"
          className="custom_dropdown"
        >
          <NavDropdown.Item
            eventKey="1"
            className="fs-14 fw-500"
            onClick={() => {
              handleSelect(4);
            }}
          >
            Goods requests
          </NavDropdown.Item>
          {/* <NavDropdown.Item
            eventKey="2"
            className="fs-14 fw-500"
          >
            Return requests
          </NavDropdown.Item> */}
        </NavDropdown>
      ),
      content: requestsContent,
      value: 4,
    },
    {
      name: 'Advertisements',
      content: <TabsAdvertisements businessId={location.state} />,
      value: 5,
    },
    // {
    //   name: (
    //     <NavDropdown
    //       title="Payments"
    //       id="nav-dropdown"
    //       className="custom_dropdown"
    //     >
    //       <NavDropdown.Item
    //         eventKey="1"
    //         className="fs-14 fw-500"
    //         onClick={handleSelect}
    //       >
    //         Pending payments
    //       </NavDropdown.Item>
    //       <NavDropdown.Item
    //         eventKey="2"
    //         className="fs-14 fw-500"
    //       >
    //         Payment history
    //       </NavDropdown.Item>
    //     </NavDropdown>
    //   ),
    //   content: paymentsContent,
    //   value: 6,
    // },
  ]);
  const [currentTab, setCurrentTab] = useState<any>(
    getKey(sellerProfile.currentTab)
      ? () => {
          let temp: any = getKey(sellerProfile.currentTab);
          console.log('hii', temp);
          let current = {};
          switch (temp?.toString()) {
            case '0':
              current = {
                name: 'Business & KYC details',
                content: (
                  <BusinessKycDetails
                    handleAddressChange={handleAddressChange}
                    currentIndex={currentIndex}
                    show={editSellerProfile}
                    profileData={profileData}
                    editData={editData}
                    category={category}
                    businessTypes={businessTypes}
                    onDetailsChange={(name: string, value: string) => {
                      handleDetailsChange(name, value);
                    }}
                    onCategorySelect={(event: any) => {
                      handleCategorySelect(event);
                    }}
                    onBusinessTypesSelect={(event: any) => {
                      handleBusinessType(event);
                    }}
                    businessCertificate={businessCertificate}
                    onCertificateChange={handleCertificate}
                    onPendingCertificateChange={handlePendingCertificate}
                  />
                ),
                value: 0,
              };
              break;
            case '1':
              current = { name: 'Catalogue', content: <Catalogue />, value: 1 };
              break;
            case '2':
              current = {
                name: (
                  <>
                    <NavDropdown
                      title="Inventory"
                      id="nav-dropdown"
                      className="custom_dropdown"
                    >
                      <NavDropdown.Item
                        eventKey="1"
                        className="fs-14 fw-500"
                        onClick={() => {
                          handleSelect(1);
                        }}
                      >
                        Seller Inventory
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        eventKey="2"
                        className="fs-14 fw-500"
                        onClick={() => {
                          handleSelect(2);
                        }}
                      >
                        History of inventory
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        eventKey="3"
                        className="fs-14 fw-500"
                        onClick={() => {
                          handleSelect(3);
                        }}
                      >
                        Stock details
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                ),
                content: inventoryContent,
                value: 2,
              };
              break;
            case '4':
              current = {
                name: (
                  <NavDropdown
                    title="Requests"
                    id="nav-dropdown"
                    className="custom_dropdown"
                  >
                    <NavDropdown.Item
                      eventKey="1"
                      className="fs-14 fw-500"
                      onClick={() => {
                        handleSelect(4);
                      }}
                    >
                      Goods requests
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
            eventKey="2"
            className="fs-14 fw-500"
          >
            Return requests
          </NavDropdown.Item> */}
                  </NavDropdown>
                ),
                content: requestsContent,
                value: 4,
              };
              break;
            case '5':
              current = {
                name: 'Advertisements',
                content: <TabsAdvertisements businessId={location.state} />,
                value: 5,
              };
              break;
          }
          return current;
        }
      : {
          name: 'Business & KYC details',
          content: (
            <BusinessKycDetails
              handleAddressChange={handleAddressChange}
              currentIndex={currentIndex}
              show={editSellerProfile}
              profileData={profileData}
              editData={editData}
              category={category}
              businessTypes={businessTypes}
              onDetailsChange={(name: string, value: string) => {
                handleDetailsChange(name, value);
              }}
              onCategorySelect={(event: any) => {
                handleCategorySelect(event);
              }}
              onBusinessTypesSelect={(event: any) => {
                handleBusinessType(event);
              }}
              businessCertificate={businessCertificate}
              onCertificateChange={handleCertificate}
              onPendingCertificateChange={handlePendingCertificate}
            />
          ),
          value: 0,
        }
  );
  const createTabs = () => {
    const localTab = tab;
    let current = currentTab;
    const allTabs = localTab.map((tab) => {
      return (
        <li className="nav-item">
          <a
            href="/"
            className={
              current.value == tab.value
                ? 'nav-link active fs-16 fw-600 pb-5 text-active-dark'
                : 'nav-link fs-16 fw-600 pb-5 text-active-dark'
            }
            data-bs-toggle="tab"
            onClick={() => handleSelectTab(tab)}
          >
            {tab.name}
          </a>
        </li>
      );
    });
    return allTabs;
  };
  const handleSelectTab = async (tab: any) => {
    setEditSellerProfile(false);
    setKey(sellerProfile.currentTab, tab.value);
    if (tab.value === 2 || tab.value === 3) {
      setCurrentTab(tab);
    } else if (tab.value === 0) {
      setLoading(true);
      await fetchProfileData();
      await fetchCategoryData();
      await fetchBusinessTypesData();
      await fetchCertificatesData();
      setCurrentTab({
        name: 'Business & KYC details',
        content: (
          <BusinessKycDetails
            handleAddressChange={handleAddressChange}
            currentIndex={currentIndex}
            show={editSellerProfile}
            profileData={profileData}
            editData={editData}
            category={category}
            businessTypes={businessTypes}
            onDetailsChange={(name: string, value: string) => {
              handleDetailsChange(name, value);
            }}
            onCategorySelect={(event: any) => {
              handleCategorySelect(event);
            }}
            onBusinessTypesSelect={(event: any) => {
              handleBusinessType(event);
            }}
            businessCertificate={businessCertificate}
            onCertificateChange={handleCertificate}
            onPendingCertificateChange={handlePendingCertificate}
            handleShowExpiry={(index: number) => {
              handleShowExpiry(index);
            }}
            handleHideExpiry={handleHideExpiry}
            handleExpirySelect={(value: string, index: number): void =>
              handleExpirySelect(value, index)
            }
            showExpiry={showExpiry}
          />
        ),
        value: 0,
      });
      setLoading(false);
    } else {
      setCurrentTab(tab);
    }
  };
  // useEffect(()=>{
  //    (async () => {
  //     setLoading(true);
  //     await fetchProfileData();
  //     await fetchCategoryData();
  //     await fetchBusinessTypesData();
  //     await fetchCertificatesData();
  //     setCurrentTab({
  //       name: "Business & KYC details",
  //       content: (
  //         <BusinessKycDetails
  // handleAddressChange = { handleAddressChange };
  //           show={editSellerProfile}
  //           profileData={profileData}
  //           editData={editData}
  //           category={category}
  //           businessTypes={businessTypes}
  //           onDetailsChange={(name: string,value: string) => {
  //             handleDetailsChange(name,value);
  //           }}
  //           onCategorySelect={(event: any) => {
  //             handleCategorySelect(event);
  //           }}
  //           onBusinessTypesSelect={(event: any) => {
  //             handleBusinessType(event);
  //           }}
  //           businessCertificate={businessCertificate}
  //           onCertificateChange={handleCertificate}
  //           onPendingCertificateChange={handlePendingCertificate}
  //           handleShowExpiry={(index: number) => {
  //             handleShowExpiry(index);
  //           }}
  //           currentIndex={currentIndex}
  //           handleHideExpiry={handleHideExpiry}
  //           handleExpirySelect={(value: string,index: number): void =>
  //             handleExpirySelect(value,index)
  //           }
  //           showExpiry={showExpiry}
  //         />
  //       ),
  //   value:0,
  //     });
  //     setLoading(false);
  //   })();
  // },[currentTab.value===0])
  const renderTooltip = (props: any) => (
    <Tooltip
      className="tooltip w-lg-275px"
      {...props}
    >
      <p className="text-white mb-0 fs-14 fw-500">
        This account is verified by admin.
      </p>
      <span className="text-muted fw-13">
        Account verified on{' '}
        {Method.convertDateToDDMMYYYY(
          profileData?.business.KYC.statusUpdatedAt
        )}
      </span>
    </Tooltip>
  );
  const handleKycStatusChange = (event: any) => {
    setStatusLoading(true);
    if (event.value === InfoRequired - 1) {
      showInfoRequire();
    } else if (event.value === Rejected - 1) {
      showInfoRejected();
    } else if (event.value === Accepted - 1) {
      showInfoAccepted();
    } else if (event.value === UnderReview - 1) {
      handleKycStatusUpdate(event.value);
    }
    setStatusLoading(false);
  };
  const handleKycStatusUpdate = async (status: number) => {
    setStatusLoading(true);
    let apiService = new APICallService(
      seller.kycStatusUpdate,
      sellerJSON.kycStatusUpdate({
        status: status,
        message: message,
      }),
      {
        id: location.state,
      }
    );
    let response = await apiService.callAPI();
    if (response) {
      let edit: any = { ...profileData };
      if (edit.business?.KYC) {
        edit.business.KYC.status = status;
        edit.business.KYC.statusMessage = message;
      }
      setProfileData(edit);
      setEditData(edit);
      toast.success(sellerToast.kycStatusUpdate);
    }
    setInfoRequire(false);
    setInfoReject(false);
    setStatusLoading(false);
  };
  const handleMessageChange = (event: any) => {
    setMessage(event.target.value.trimStart());
  };
  const showInfoRequire = () => {
    setMessage(editData?.business.KYC.statusMessage);
    setInfoRequire(true);
  };
  const showInfoRejected = () => {
    setMessage(editData?.business.KYC.statusMessage);
    setInfoReject(true);
  };
  const showInfoAccepted = () => {
    setMessage('');
    setInfoAccept(true);
  };
  const getTabContent = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return (
          <BusinessKycDetails
            handleAddressChange={handleAddressChange}
            currentIndex={currentIndex}
            show={editSellerProfile}
            profileData={profileData}
            editData={editData}
            category={category}
            businessTypes={businessTypes}
            onDetailsChange={handleDetailsChange}
            onCategorySelect={handleCategorySelect}
            onBusinessTypesSelect={handleBusinessType}
            businessCertificate={businessCertificate}
            onCertificateChange={handleCertificate}
            onPendingCertificateChange={handlePendingCertificate}
            handleShowExpiry={handleShowExpiry}
            handleHideExpiry={handleHideExpiry}
            handleExpirySelect={handleExpirySelect}
            showExpiry={showExpiry}
          />
        );
      case 1:
        return <Catalogue />;
      case 2:
        handleSelect(selectedSubTab);
        return inventoryContent;
      case 4:
        return <SellerGoodsRequest businessId={location.state} />;
      case 5:
        return <TabsAdvertisements businessId={location.state} />;
      default:
        return null;
    }
  };
  const currentTabContent = useMemo(() => getTabContent(currentTab.value), [
    currentTab.value,
    selectedSubTab,
    editSellerProfile,
    profileData,
    editData,
    category,
    businessTypes,
    businessCertificate,
    showExpiry,
    currentIndex,
  ]);

  useEffect(() => {
    if (editSellerProfile || showExpiry) {
      setCurrentTab({
        name: 'Business & KYC details',
        value: 0,
      });
    }
  }, [editSellerProfile, showExpiry]);

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          {profileData?.pendingCertificates.length ? (
            <>
              <Notification
                bgColor="bg-light-danger"
                iconColor="#e55451"
                text={`Please upload business certificate for
                    ${profileData?.pendingCertificates
                      .map((u: any) => u.title)
                      .join(', ')}`}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <div className="p-9">
        <Col
          xl={12}
          className="align-items-center"
        >
          <Row className="align-items-center mb-8">
            <Col sm>
              <h1 className="main-title fw-bolder mb-0">Seller profile</h1>
            </Col>
            {loading ? (
              <></>
            ) : (
              <>
                {currentUser?.role.some(
                  (obj: any) =>
                    obj.type === FinanceManager ||
                    obj.type === FulfillmentManager
                ) ? (
                  <></>
                ) : (
                  <>
                    {profileData?.business.goodsLoadingAreas.length ? (
                      <></>
                    ) : (
                      <>
                        {' '}
                        <Col sm="auto">
                          <Button
                            variant="light-danger"
                            className="btn-lg"
                            onClick={() => {
                              navigate('/master/seller-product-zone');
                            }}
                          >
                            Assign products zone
                          </Button>
                        </Col>
                      </>
                    )}
                  </>
                )}
                <Col sm="auto">
                  {editSellerProfile ? (
                    <>
                      <Button
                        variant="light"
                        size="lg"
                        className="fs-16 text-gray fw-700 me-4"
                        onClick={() => {
                          setEditSellerProfile(!editSellerProfile);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {currentUser?.role.some(
                    (obj: any) =>
                      obj.type === FinanceManager ||
                      obj.type === FulfillmentManager
                  ) ? (
                    <></>
                  ) : (
                    <>
                      <Button
                        variant={
                          editSellerProfile ? 'primary' : 'light-primary'
                        }
                        size="lg"
                        className="fs-16 fw-700"
                        onClick={() => {
                          editSellerProfile
                            ? onSaveChanges()
                            : setEditSellerProfile(!editSellerProfile);
                        }}
                        disabled={editSellerProfile ? disabled : false}
                      >
                        {editSellerProfile ? (
                          saveLoading ? (
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          ) : (
                            'Save changes'
                          )
                        ) : (
                          'Edit profile details'
                        )}
                      </Button>
                    </>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Col>
        {loading ? (
          <></>
        ) : (
          <>
            {profileData?.business.KYC.status !== Accepted - 1 ? (
              <div className="custom-alert alert bg-light-secondary border-secondary mb-7">
                <Row className="align-items-center">
                  <Col
                    md
                    className="mb-lg-0 mb-5"
                  >
                    {profileData?.business.KYC.status === NewRequests - 1 ? (
                      <h4 className="alert-heading fs-20 fw-bolder">
                        Business verification pending{' '}
                      </h4>
                    ) : (
                      ''
                    )}
                    {profileData?.business.KYC.status === UnderReview - 1 ? (
                      <h4 className="alert-heading fs-20 fw-bolder">
                        Business verification under review{' '}
                      </h4>
                    ) : (
                      ''
                    )}
                    {profileData?.business.KYC.status === InfoRequired - 1 ? (
                      <h4 className="alert-heading fs-20 fw-bolder">
                        Information required from seller{' '}
                      </h4>
                    ) : (
                      ''
                    )}
                    {profileData?.business.KYC.status === Rejected - 1 ? (
                      <h4 className="alert-heading fs-20 fw-bolder">
                        Business verification rejected{' '}
                      </h4>
                    ) : (
                      ''
                    )}
                    {infoRequire ? (
                      <InfoRequiredModal
                        show={infoRequire}
                        onHide={() => {
                          setInfoRequire(!infoRequire);
                        }}
                        name={profileData?.business.ownerName}
                        onMessageChange={handleMessageChange}
                        onSave={() => {
                          handleKycStatusUpdate(InfoRequired - 1);
                        }}
                        loading={statusLoading}
                        message={message}
                      />
                    ) : (
                      <></>
                    )}
                    {infoReject ? (
                      <RejectBusiness
                        show={infoReject}
                        onHide={() => {
                          setInfoReject(!infoReject);
                        }}
                        name={profileData?.business.ownerName}
                        onMessageChange={handleMessageChange}
                        onSave={() => {
                          handleKycStatusUpdate(Rejected - 1);
                        }}
                        loading={statusLoading}
                        message={message}
                      />
                    ) : (
                      <></>
                    )}
                    {infoAccept ? (
                      <AcceptBusiness
                        show={infoAccept}
                        onHide={() => {
                          setInfoAccept(!infoAccept);
                        }}
                        name={profileData?.business.ownerName}
                        onSave={() => {
                          handleKycStatusUpdate(Accepted - 1);
                        }}
                        loading={statusLoading}
                      />
                    ) : (
                      <></>
                    )}
                    {profileData?.business.KYC.status === InfoRequired - 1 ? (
                      <div className="fs-16 fw-600 text-danger">
                        {profileData?.business.KYC.statusMessage} {''}
                        {currentUser?.role.some(
                          (obj: any) =>
                            obj.type === FinanceManager ||
                            obj.type === FulfillmentManager
                        ) ? (
                          <></>
                        ) : (
                          <button
                            className="btn btn-link btn-flush fs-16 fw-bold text-danger pb-1 text-decoration-underline"
                            onClick={() => {
                              showInfoRequire();
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    ) : profileData?.business.KYC.status === Rejected - 1 ? (
                      <div className="fs-16 fw-600 text-danger">
                        {profileData?.business.KYC.statusMessage}
                      </div>
                    ) : (
                      <div className="fs-16 fw-500">
                        Please verify the business KYC details of the seller.
                        You can ask for more information if required.
                      </div>
                    )}
                  </Col>
                  <Col md="auto">
                    <CustomSelect
                      minHeight={'46px'}
                      hideSelectedOptions={true}
                      backgroundColor={'white'}
                      isSearchable={false}
                      disabled={
                        currentUser?.role.some(
                          (obj: any) =>
                            obj.type === FinanceManager ||
                            obj.type === FulfillmentManager
                        ) || statusLoading
                      }
                      default={
                        profileData?.business.KYC.status === NewRequests - 1
                          ? {
                              value: NewRequests - 1,
                              name: 'newRequest',
                              title: 'New request',
                              label: (
                                <>
                                  <img
                                    src={Request}
                                    height={11}
                                    className=" me-2"
                                    alt=""
                                  />
                                  <span>New request</span>
                                </>
                              ),
                            }
                          : kycOptions[
                              kycOptions.findIndex((el) => {
                                return (
                                  el.value === profileData?.business.KYC.status
                                );
                              })
                            ]
                      }
                      value={
                        profileData?.business.KYC.status === NewRequests - 1
                          ? {
                              value: NewRequests - 1,
                              name: 'newRequest',
                              title: 'New request',
                              label: (
                                <>
                                  <img
                                    src={Request}
                                    height={11}
                                    className=" me-2"
                                    alt=""
                                  />
                                  <span>New request</span>
                                </>
                              ),
                            }
                          : kycOptions[
                              kycOptions.findIndex((el) => {
                                return (
                                  el.value === profileData?.business.KYC.status
                                );
                              })
                            ]
                      }
                      onChange={handleKycStatusChange}
                      options={
                        currentUser?.role.some(
                          (obj: any) =>
                            obj.type === FinanceManager ||
                            obj.type === FulfillmentManager
                        )
                          ? 0
                          : kycOptions
                      }
                      isMulti={false}
                      isOptionDisabled={(option: any) =>
                        option.value === NewRequests - 1
                      }
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <></>
            )}
            <Card className="border mb-5 mb-xl-8">
              <Card.Body className="pb-0 pt-9">
                <Row className="align-items-center mb-3">
                  <Col
                    md="auto"
                    className="me-md-3 me-0"
                  >
                    <div className="symbol symbol-100 symbol-lg-200 symbol-fixed bg-light position-relative">
                      <div className="image-input d-flex flex-center rounded w-lg-200px h-lg-200px">
                        <div
                          className="image-input-wrapper shadow-none bgi-cover border min-w-md-200px min-w-150px min-h-md-200px min-h-150px"
                          style={{
                            backgroundImage: `url(${editData?.business.image})`,
                            backgroundPosition: 'right',
                          }}
                        ></div>
                        {editSellerProfile ? (
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-50px h-50px top-100 bottom-0"
                            data-kt-image-input-action="change"
                            title=""
                          >
                            <img
                              src={edit}
                              height={33}
                              width={33}
                              alt=""
                            />
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => onProfileChange(e)}
                            />
                            <input
                              type="hidden"
                              name="avatar_remove"
                            />
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md>
                    <Row>
                      <Col lg={12}>
                        <div className="text-black fs-22 fw-700 d-flex align-items-center mb-2">
                          {profileData?.business.name}
                          {profileData?.business.KYC.status === Accepted - 1 ? (
                            <OverlayTrigger
                              placement="right"
                              // delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                              trigger="hover"
                            >
                              <img
                                className="img-fluid mh-md-27px mw-md-85px ms-3"
                                src={Verified}
                                alt="Verified"
                              />
                            </OverlayTrigger>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                          <div className="d-flex align-items-center text-black fs-16 fw-500 me-5 mb-2">
                            <span className="svg-icon svg-icon-4 me-2">
                              <img
                                src={PhoneCall}
                                alt="Phone number"
                              />
                            </span>{' '}
                            {profileData?.business.phoneCountry}{' '}
                            {profileData?.business.phone}{' '}
                          </div>
                          <div className="d-flex align-items-center text-black fs-16 fw-500 mb-2">
                            <span className="svg-icon svg-icon-4 me-2">
                              <img
                                src={Email}
                                alt="Email"
                              />
                            </span>
                            {profileData?.business.email}{' '}
                          </div>
                        </div>
                      </Col>
                      <Col lg={'auto'}>
                        <div className="border rounded bg-light py-3 px-4 mb-3">
                          <div className="d-flex align-items-center">
                            <div
                              className="fs-22 text-black fw-bolder"
                              data-kt-countup="true"
                              data-kt-countup-value="34"
                            >
                              {profileData?.totalProducts}{' '}
                            </div>
                          </div>
                          <div className="fw-bold fs-16 fw-500 text-gray">
                            Products listed
                          </div>
                        </div>
                      </Col>
                      {profileData?.business.goodsLoadingAreas.length ? (
                        <Col lg={'auto'}>
                          <div className="border rounded bg-light py-3 px-4 mb-3">
                            <div className="d-flex align-items-center">
                              <div
                                className="fs-22 text-black fw-bolder"
                                data-kt-countup="true"
                                data-kt-countup-value="34"
                              >
                                {`${profileData?.business.goodsLoadingAreas
                                  .map((u: any) => u.name)
                                  .join(' & ')}`}
                              </div>
                            </div>
                            <div className="fw-bold fs-16 fw-500 text-gray">
                              Products zone
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <></>
                      )}
                      {profileData?.business.totalSales ? (
                        <Col lg={'auto'}>
                          <div className="border rounded bg-light py-3 px-4 mb-3">
                            <div className="d-flex align-items-center">
                              <div
                                className="fs-22 text-black fw-bolder"
                                data-kt-countup="true"
                                data-kt-countup-value="34"
                              >
                                TSh{' '}
                                {`${Method.getGeneralizedAmount(
                                  profileData?.business.totalSales
                                )}`}
                              </div>
                            </div>
                            <div className="fw-bold fs-16 fw-500 text-gray">
                              Total sales
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Col>
                </Row>
                <div className="d-flex flex-wrap">
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                    {createTabs()}
                  </ul>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loader loading={loading} />
          </div>
        ) : (
          <div className="tab-pane fade show active row g-5 g-xxl-8">
            <> {currentTabContent}</>
          </div>
        )}
      </div>
    </>
  );
};
export default SellerProfile;
