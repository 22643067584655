import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import StoreSVG from '../../../../../_admin/assets/media/dashboard/store.svg';
import MasterSVG from '../../../../../_admin/assets/media/dashboard/master.svg';
import SellerSVG from '../../../../../_admin/assets/media/dashboard/seller.svg';
import PlatformSVG from '../../../../../_admin/assets/media/dashboard/platform.svg';
import AdvertisementSVG from '../../../../../_admin/assets/media/dashboard/advertisement.svg';
import ContactSVG from '../../../../../_admin/assets/media/dashboard/contact.svg';
import CustomerSVG from '../../../../../_admin/assets/media/dashboard/customers.svg';
import ProductSVG from '../../../../../_admin/assets/media/dashboard/inventory.svg';
import CustomerServiceSVG from '../../../../../_admin/assets/media/dashboard/customer_service.svg';
import PendingCertificatesSVG from '../../../../../_admin/assets/media/dashboard/pending_certificates.svg';
import DashboardIcon from '../../../../../_admin/assets/media/dashboard/dashboard.svg';
import CustomersIcon from '../../../../../_admin/assets/media/dashboard/customers.svg';
import MyTeamIcon from '../../../../../_admin/assets/media/dashboard/my-team.svg';
import OrdersDelivery from '../../../../../_admin/assets/media/dashboard/orders-delivery.svg';
import { useEffect, useState } from 'react';
import APICallService from '../../../../../api/apiCallService';
import { SIDEBAR } from '../../../../../api/apiEndPoints';
import { useAuth } from '../../../../../app/modules/auth';
import {
  AssistAdmin,
  FinanceManager,
  FulfillmentManager,
  LoadingBayManager,
  Manager,
  ServiceMember,
} from '../../../../../utils/constants';
import InventorySVG from '../../../../../_admin/assets/media/dashboard/_inventory.svg';
const SidebarMenuMain = () => {
  const { currentUser } = useAuth();
  const [count, setCount] = useState({
    pendingKYC: 0,
    totalSellers: 0,
    pendingProducts: 0,
  });
  useEffect(() => {
    (async () => {
      if (currentUser?.userType === AssistAdmin) {
      } else {
        await fetchCount();
      }
    })();
  }, []);
  const fetchCount = async () => {
    let apiService = new APICallService(SIDEBAR);
    let response = await apiService.callAPI();
    if (response) {
      setCount(response);
    }
  };
  const renderCommonItems = () => (
    <>
      {/* <SidebarMenuItem
        to="/dashboard"
        icon={DashboardIcon}
        title={'Dashboard'}
        fontIcon="bi-app-indicator"
      /> */}
      <SidebarMenuItem
        to="/customers/view-customers"
        icon={CustomersIcon}
        title={'Customers'}
        fontIcon="bi-app-indicator"
      />
      {currentUser?.role.some((obj: any) => obj.type === Manager) && (
        <SidebarMenuItem
          to="/my-team"
          icon={MyTeamIcon}
          title={'My team'}
          fontIcon="bi-app-indicator"
        />
      )}
      <SidebarMenuItem
        to="/orders"
        title="Orders & delivery"
        icon={OrdersDelivery}
        fontIcon="bi-person"
      />
    </>
  );
  return (
    <>
      {currentUser?.userType === AssistAdmin ? (
        <>
          {currentUser?.role.some((obj: any) => obj.type === FinanceManager) ? (
            <>
              <SidebarMenuItem
                to="/dashboard"
                icon={DashboardIcon}
                title={'Dashboard'}
                fontIcon="bi-app-indicator"
              />
              {/* <SidebarMenuItemWithSub
                to="/payments"
                title="Payments"
                icon={PaymentsSVG}
                fontIcon="bi-person"
              >
                <SidebarMenuItem
                  to="/payments/payment-dashboard"
                  title="Dashboard"
                  hasBullet={false}
                />
                <SidebarMenuItem
                  to="/payments/payments-to-sellers"
                  title="Payments to sellers"
                  hasBullet={false}
                />
                <SidebarMenuItem
                  to="/payments/refunds-to-customers"
                  title="Refunds to customers"
                  hasBullet={false}
                />
              </SidebarMenuItemWithSub> */}
              <SidebarMenuItemWithSub
                to="/sellers/"
                title="Seller"
                icon={StoreSVG}
                fontIcon="bi-person"
              >
                <SidebarMenuItem
                  to="/sellers/list-seller"
                  title="All sellers"
                  hasCount={count.totalSellers > 0}
                  counter={count.totalSellers}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItem
                to="/customers/view-customers"
                icon={CustomersIcon}
                title={'Customers'}
                fontIcon="bi-app-indicator"
              />
              <SidebarMenuItemWithSub
                to="/finance-reports/"
                title="Finance Reports"
                icon={MasterSVG}
                fontIcon="bi-person"
              >
                <SidebarMenuItem
                  to="finance-reports/outward-report"
                  title="Outward Reports"
                  hasBullet={false}
                />
                <SidebarMenuItem
                  to="finance-reports/return-product-report"
                  title="Return Product Reports"
                  hasBullet={false}
                />
                {/* <SidebarMenuItem
              to="/customers/refunds-to-customers"
              title="Refunds to Customers"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/customers/receivable-report"
              title="Receivable Report"
              hasBullet={false}
            /> */}
              </SidebarMenuItemWithSub>
              <SidebarMenuItem
                to="/orders"
                title="Orders & delivery"
                icon={OrdersDelivery}
                fontIcon="bi-person"
              />
              {/* <SidebarMenuItem
                to="/orders"
                title="Orders & delivery"
                icon={OrdersDelivery}
                fontIcon="bi-person"
              /> */}
              <SidebarMenuItem
                to="/all-products"
                title="All products"
                icon={ProductSVG}
                fontIcon="bi-person"
                hasCount={count.pendingProducts > 0}
                counter={count.pendingProducts}
              />
              <SidebarMenuItemWithSub
                to="/inventory/"
                title="Inventory"
                icon={InventorySVG}
                fontIcon="bi-person"
              >
                <SidebarMenuItem
                  to="/inventory/goods-in-warehouse"
                  title="Goods in warehouse"
                  hasBullet={false}
                />
                <SidebarMenuItem
                  to="/inventory/low-stock-list"
                  title="Low stock list"
                  hasBullet={false}
                />
                <SidebarMenuItem
                  to="/inventory/goods-requests"
                  title="Goods requests"
                  hasBullet={false}
                />
                {/* <SidebarMenuItem
              to="/inventory/return-goods"
              title="Return goods"
              hasBullet={false}
            /> */}
              </SidebarMenuItemWithSub>
              <SidebarMenuItem
                to="/advertisement/view-advertisement"
                title="Advertising"
                icon={AdvertisementSVG}
                fontIcon="bi-person"
              />
              <SidebarMenuItem
                to="/platform-fees/fees"
                icon={PlatformSVG}
                title="Platform fees"
                fontIcon="bi-app-indicator"
              />
            </>
          ) : (
            <>
              {currentUser?.role.some(
                (obj: any) => obj.type === LoadingBayManager
              ) ? (
                <>
                  <SidebarMenuItem
                    to="/orders"
                    title="Orders & delivery"
                    icon={OrdersDelivery}
                    fontIcon="bi-person"
                  />
                  {/* <SidebarMenuItem
                    to="/all-products"
                    title="All products"
                    icon={ProductSVG}
                    fontIcon="bi-person"
                    hasCount={count.pendingProducts > 0}
                    counter={count.pendingProducts}
                  /> */}
                  <SidebarMenuItemWithSub
                    to="/inventory/"
                    title="Inventory"
                    icon={InventorySVG}
                    fontIcon="bi-person"
                  >
                    <SidebarMenuItem
                      to="/inventory/goods-in-warehouse"
                      title="Goods in warehouse"
                      hasBullet={false}
                    />
                    <SidebarMenuItem
                      to="/inventory/low-stock-list"
                      title="Low stock list"
                      hasBullet={false}
                    />
                    <SidebarMenuItem
                      to="/inventory/goods-requests"
                      title="Goods requests"
                      hasBullet={false}
                    />
                  </SidebarMenuItemWithSub>
                </>
              ) : (
                <>
                  {currentUser?.role.some(
                    (obj: any) => obj.type === FulfillmentManager
                  ) ? (
                    <>
                      <SidebarMenuItemWithSub
                        to="/sellers/"
                        title="Seller"
                        icon={StoreSVG}
                        fontIcon="bi-person"
                      >
                        <SidebarMenuItem
                          to="/sellers/list-seller"
                          title="All sellers"
                          hasCount={count.totalSellers > 0}
                          counter={count.totalSellers}
                        />
                      </SidebarMenuItemWithSub>
                      <SidebarMenuItem
                        to="/customers/view-customers"
                        icon={CustomersIcon}
                        title={'Customers'}
                        fontIcon="bi-app-indicator"
                      />
                      {/* <SidebarMenuItem
                        to="/orders"
                        title="Orders & delivery"
                        icon={OrdersDelivery}
                        fontIcon="bi-person"
                      /> */}
                      <SidebarMenuItem
                        to="/orders"
                        title="Orders & delivery"
                        icon={OrdersDelivery}
                        fontIcon="bi-person"
                      />
                      <SidebarMenuItemWithSub
                        to="/master/drivers"
                        title="Master"
                        icon={MasterSVG}
                        fontIcon="bi-person"
                      >
                        <SidebarMenuItem
                          to="/master/drivers"
                          title="Drivers"
                          hasBullet={false}
                        />
                        <SidebarMenuItem
                          to="/master/vehicles"
                          title="Vehicles"
                          hasBullet={false}
                        />
                        <SidebarMenuItem
                          to="/master/seller-product-zone"
                          title="Seller products zone"
                          hasBullet={false}
                        />
                        {/* <SidebarMenuItem
          to="/error/maintanance"
          title="Master products"
          hasBullet={false}
        /> */}
                      </SidebarMenuItemWithSub>
                      <SidebarMenuItemWithSub
                        to="/inventory/"
                        title="Inventory"
                        icon={InventorySVG}
                        fontIcon="bi-person"
                      >
                        <SidebarMenuItem
                          to="/inventory/goods-in-warehouse"
                          title="Goods in warehouse"
                          hasBullet={false}
                        />
                        <SidebarMenuItem
                          to="/inventory/low-stock-list"
                          title="Low stock list"
                          hasBullet={false}
                        />
                        <SidebarMenuItem
                          to="/inventory/goods-requests"
                          title="Goods requests"
                          hasBullet={false}
                        />
                        {/* <SidebarMenuItem
              to="/inventory/return-goods"
              title="Return goods"
              hasBullet={false}
            /> */}
                      </SidebarMenuItemWithSub>
                    </>
                  ) : (
                    renderCommonItems()
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {/* <SidebarMenuItem
            to="/list-seller"
            icon={StoreSVG}
            title="Seller"
            fontIcon="bi-app-indicator"
            hasCount={count.totalSellers > 0}
            counter={count.totalSellers}
          /> */}
          <SidebarMenuItem
            to="/dashboard"
            icon={DashboardIcon}
            title={'Dashboard'}
            fontIcon="bi-app-indicator"
          />
          <SidebarMenuItemWithSub
            to="/sellers/"
            title="Seller"
            icon={StoreSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/sellers/list-seller"
              title="All sellers"
              hasCount={count.totalSellers > 0}
              counter={count.totalSellers}
            />
            <SidebarMenuItem
              to="/sellers/kyc-verification"
              title="KYC verification"
              hasCount={count.pendingKYC > 0}
              counter={count.pendingKYC}
            ></SidebarMenuItem>
            {/* Pending certificates */}
            <SidebarMenuItem
              to="/sellers/pending-certificates"
              title="Pending certificates"
            />
            {/* Payment to sellers */}
            <SidebarMenuItem
              to="/sellers/payments-to-sellers"
              title="Payments to sellers"
            />
            {/* Sales Report */}
            <SidebarMenuItem
              to="/sellers/sales-report"
              title="Sales Report"
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/customers"
            title="Customers"
            icon={MasterSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/customers"
              title="All Customers"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/customers/refunds-to-customers"
              title="Refunds to Customers"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/customers/receivable-report"
              title="Receivable Report"
              hasBullet={false}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/finance-reports/"
            title="Finance Reports"
            icon={MasterSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="finance-reports/outward-report"
              title="Outward Reports"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="finance-reports/return-product-report"
              title="Return Product Reports"
              hasBullet={false}
            />
            {/* <SidebarMenuItem
              to="/customers/refunds-to-customers"
              title="Refunds to Customers"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/customers/receivable-report"
              title="Receivable Report"
              hasBullet={false}
            /> */}
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to="/orders"
            title="Orders & delivery"
            icon={OrdersDelivery}
            fontIcon="bi-person"
          />
          <SidebarMenuItemWithSub
            to="/master/category"
            title="Master"
            icon={MasterSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/master/category"
              title="Categories"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/business-types"
              title="Business types"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/business-certificates"
              title="Business certificates"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/brands"
              title="Brands"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/technical-details"
              title="Technical details"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/product-variants"
              title="Product variants"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/products/master-products"
              title="Master products"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/drivers"
              title="Drivers"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/vehicles"
              title="Vehicles"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/loading-areas"
              title="Vehicle loading areas"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/seller-product-zone"
              title="Seller products zone"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/finance-members"
              title="Finance members"
              hasBullet={false}
            />
            {/* <SidebarMenuItem
          to="/error/maintanance"
          title="Master products"
          hasBullet={false}
        /> */}
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/inventory/"
            title="Inventory"
            icon={InventorySVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/inventory/goods-in-warehouse"
              title="Goods in warehouse"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/inventory/low-stock-list"
              title="Low stock list"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/inventory/goods-requests"
              title="Goods requests"
              hasBullet={false}
            />
            {/* <SidebarMenuItem
              to="/inventory/return-goods"
              title="Return goods"
              hasBullet={false}
            /> */}
            <SidebarMenuItem
              to="/inventory/received-direct-goods"
              title="Received direct goods"
              hasBullet={false}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to="/customer-service"
            title="Customer service"
            icon={CustomerServiceSVG}
            fontIcon="bi-person"
          />
          <SidebarMenuItem
            to="/all-products"
            title="All products"
            icon={ProductSVG}
            fontIcon="bi-person"
            hasCount={count.pendingProducts > 0}
            counter={count.pendingProducts}
          />
          <SidebarMenuItem
            to="/platform-fees/fees"
            icon={PlatformSVG}
            title="Platform fees"
            fontIcon="bi-app-indicator"
          />
          <SidebarMenuItem
            to="/advertisement/view-advertisement"
            title="Advertising"
            icon={AdvertisementSVG}
            fontIcon="bi-person"
          />
          <SidebarMenuItem
            to="/contact-inquiries/inquiries"
            icon={ContactSVG}
            title="Contact inquiries"
            fontIcon="bi-app-indicator"
          />
          <SidebarMenuItem
            to="/custom-notification"
            title="Custom Notification"
            icon={AdvertisementSVG}
            fontIcon="bi-person"
          />
          <SidebarMenuItemWithSub
            to="/settings/"
            title="Settings"
            icon={MasterSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/settings/privacy-policy"
              title="Privacy Policy"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/settings/terms-condition"
              title="Terms & Conditions"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/settings/app-settings"
              title="App Settings"
              hasBullet={false}
            />
          </SidebarMenuItemWithSub>
        </>
      )}
    </>
  );
};
export { SidebarMenuMain };
